import UsersList from "./UsersList";
import UsersEdit from "./UsersEdit";
import UsersCreate from "./UsersCreate";
import PersonIcon from '@material-ui/icons/Person';

export default {
    list: UsersList,
    create: UsersCreate,
    edit: UsersEdit,
    icon: PersonIcon
};