import ResidenceList from "./ResidenceList";
import ResidenceEdit from "./ResidenceEdit";
import ResidenceCreate from "./ResidenceCreate";
import HomeIcon from '@material-ui/icons/Home';

export default {
    list: ResidenceList,
    create: ResidenceCreate,
    edit: ResidenceEdit,
    icon: HomeIcon
};