import {stringify} from "query-string";
import {
    CREATE,
    DELETE,
    DELETE_MANY,
    fetchUtils,
    GET_LIST,
    GET_MANY,
    GET_MANY_REFERENCE,
    GET_ONE,
    UPDATE
} from "react-admin";
import React from "react";
import {AppConstant} from "../utilities/constants";

const commonConvertToForm = (params) => {
    let form = new FormData();
    for (let key in params.data) {
        if (params.data.hasOwnProperty(key)) {
            //console.log("Key", key, "->", "Value", params.data[key]);
            // if (key === 'thumbnail') {
            //     //console.log(params.data[key]);
            //     if (params.data[key]) {
            //         if (params.data[key].rawFile) {
            //             form.append(`newThumbnail[]`, params.data[key].rawFile);
            //         } else {
            //             form.append(`oldThumbnail[]`, params.data[key])
            //         }
            //     }
            //     continue
            // }
            // if (key === 'ticket') {
            //     //console.log(params.data[key]);
            //     if (params.data[key]) {
            //         if (params.data[key].rawFile) {
            //             form.append(`newTicket[]`, params.data[key].rawFile);
            //         } else {
            //             form.append(`oldTicket[]`, params.data[key].url)
            //         }
            //     }
            //     continue
            // }
            if (key === 'images') {
                //console.log(params.data[key]);
                if (params.data[key]) {
                    for (let i = 0; i < params.data[key].length; i++) {
                        if (params.data[key][i].rawFile) {
                            form.append(`new_images[]`, params.data[key][i].rawFile);
                        } else {
                            if (params.data[key][i].url) {
                                form.append(`old_images[]`, params.data[key][i].url)
                            }
                        }
                    }
                }
                continue
            }
            // if (key === 'panorama') {
            //     //console.log(params.data[key]);
            //     if (params.data[key]) {
            //         for (let i = 0; i < params.data[key].length; i++) {
            //             if (params.data[key][i].rawFile) {
            //                 form.append(`newPanorama[]`, params.data[key][i].rawFile);
            //             } else {
            //                 form.append(`oldPanorama[]`, params.data[key][i].url)
            //             }
            //         }
            //     }
            //     continue
            // }
            if (typeof params.data[key] === 'object' && params.data[key] !== null) {
                form.append(`${key}`, JSON.stringify(params.data[key]));
            } else {
                form.append(`${key}`, params.data[key]);
            }
        }
    }
    return form;
};

const restaurantConvertToForm = (params) => {
    let form = new FormData();
    for (let key in params.data) {
        if (params.data.hasOwnProperty(key)) {
            if (key === 'images') {
                //console.log(params.data[key]);
                if (params.data[key]) {
                    for (let i = 0; i < params.data[key].length; i++) {
                        if (params.data[key][i].rawFile) {
                            form.append(`new_images[]`, params.data[key][i].rawFile);
                        } else {
                            if (params.data[key][i].url) {
                                form.append(`old_images[]`, params.data[key][i].url)
                            }
                        }
                    }
                }
                continue
            }
            if (key === 'menu') {
                //console.log(params.data[key]);
                if (params.data[key]) {
                    for (let i = 0; i < params.data[key].length; i++) {
                        if (params.data[key][i].rawFile) {
                            form.append(`new_menu[]`, params.data[key][i].rawFile);
                        } else {
                            if (params.data[key][i].url) {
                                form.append(`old_menu[]`, params.data[key][i].url)
                            }
                        }
                    }
                }
                continue
            }
            if (typeof params.data[key] === 'object' && params.data[key] !== null) {
                form.append(`${key}`, JSON.stringify(params.data[key]));
            } else {
                form.append(`${key}`, params.data[key]);
            }
        }
    }
    return form;
};

const shoppingConvertToForm = (params) => {
    let form = new FormData();
    for (let key in params.data) {
        if (params.data.hasOwnProperty(key)) {
            //console.log("Key", key, "->", "Value", params.data[key]);
            // if (key === 'thumbnail') {
            //     //console.log(params.data[key]);
            //     if (params.data[key]) {
            //         if (params.data[key].rawFile) {
            //             form.append(`newThumbnail[]`, params.data[key].rawFile);
            //         } else {
            //             form.append(`oldThumbnail[]`, params.data[key])
            //         }
            //     }
            //     continue
            // }
            // if (key === 'ticket') {
            //     //console.log(params.data[key]);
            //     if (params.data[key]) {
            //         if (params.data[key].rawFile) {
            //             form.append(`newTicket[]`, params.data[key].rawFile);
            //         } else {
            //             form.append(`oldTicket[]`, params.data[key].url)
            //         }
            //     }
            //     continue
            // }
            if (key === 'images') {
                //console.log(params.data[key]);
                if (params.data[key]) {
                    for (let i = 0; i < params.data[key].length; i++) {
                        if (params.data[key][i].rawFile) {
                            form.append(`new_images[]`, params.data[key][i].rawFile);
                        } else {
                            if (params.data[key][i].url) {
                                form.append(`old_images[]`, params.data[key][i].url)
                            }
                        }
                    }
                }
                continue
            }
            if (key === 'price_table') {
                if (params.data[key]) {
                    for (let i = 0; i < params.data[key].length; i++) {
                        if (params.data[key][i].rawFile) {
                            form.append(`new_price_table[]`, params.data[key][i].rawFile);
                        } else {
                            if (params.data[key][i].url) {
                                form.append(`old_price_table[]`, params.data[key][i].url)
                            }
                        }
                    }
                }
                continue
            }
            if (typeof params.data[key] === 'object' && params.data[key] !== null) {
                form.append(`${key}`, JSON.stringify(params.data[key]));
            } else {
                form.append(`${key}`, params.data[key]);
            }
        }
    }
    return form;
};

export default function (apiUrl, httpClient = fetchUtils.fetchJson) {
    const convertDataRequestToHTTP = (type, resource, params) => {
        //console.log("Type", type)
        let url = "";
        let options = {};
        options.user = {
            authenticated: true,
            token: "Bearer " + localStorage.getItem("token")
        };
        console.log("Resources", resource);
        switch (type) {
            case GET_LIST: {
                const {page, perPage} = params.pagination;
                const {field, order} = params.sort;
                const query = {
                    ...fetchUtils.flattenObject(params.filter),
                    sort: field,
                    order: order,
                    page: page,
                    perPage: perPage
                };
                url = `${apiUrl}/${resource}?${stringify(query)}`;
                options.method = "GET";
                break;
            }
            case GET_MANY: {
                const query = {
                    id: params.ids
                };
                url = `${apiUrl}/${resource}?${stringify(query)}`;
                options.method = "GET";
                break;
            }

            case GET_ONE:
                url = `${apiUrl}/${resource}/${params.id}`;
                options.method = "GET";
                break;

            case GET_MANY_REFERENCE: {
                const {page, perPage} = params.pagination;
                const {field, order} = params.sort;
                const query = {
                    ...fetchUtils.flattenObject(params.filter),
                    [params.target]: params.id,
                    _sort: field,
                    _order: order,
                    _start: (page - 1) * perPage,
                    _end: page * perPage
                };
                url = `${apiUrl}/${resource}?${stringify(query)}`;
                options.method = "GET";
                break;
            }

            case CREATE:
                url = `${apiUrl}/${resource}`;
                options.method = "POST";
                options.body = JSON.stringify(params.data);
                break;

            case UPDATE:
                url = `${apiUrl}/${resource}/${params.id}`;
                options.method = "PUT";
                if (resource === AppConstant.Routes.Residences) {
                    options.body = commonConvertToForm(params)
                    break;
                }
                if (resource === AppConstant.Routes.Restaurants) {
                    options.body = restaurantConvertToForm(params)
                    break;
                }
                if (resource === AppConstant.Routes.Shopping) {
                    options.body = shoppingConvertToForm(params)
                    break;
                }
                options.body = JSON.stringify(params.data);
                break;

            case DELETE:
                url = `${apiUrl}/${resource}/${params.id}`;
                options.method = "DELETE";
                break;

            case DELETE_MANY:
                url = `${apiUrl}/${resource}`;
                options.method = "DELETE";
                break;
            default:
                throw new Error(`Unsupported fetch action type ${type}`);
        }
        return {url, options};
    };

    const convertHTTPResponse = (response, type, resource, params) => {
        const {headers, json} = response;
        //console.log("Type", type);
        //console.log("Response", response);
        //debugger
        switch (type) {
            case GET_ONE:
                return {
                    data: json.data ? json.data : null,
                };
            case GET_LIST:
                return {
                    data: json.data ? json.data : [],
                    total: json.data ? parseInt(headers.get("X-Total-Count")) : 0
                };
            case GET_MANY_REFERENCE:
                return {
                    data: json.data ? json.data : [],
                    total: json.data ? parseInt(headers.get("X-Total-Count")) : 0
                };
            case GET_MANY:
                return {
                    data: json.data ? json.data : [],
                    total: json.data ? parseInt(headers.get("X-Total-Count")) : 0
                };
            case CREATE:
                return {data: {...params.data, id: json.data.id}};
            case DELETE:
                return {data: {id: null}};
            default:
                return {data: json.data};
        }
    };

    return (type, resource, params) => {
        let {url, options} = convertDataRequestToHTTP(type, resource, params);
        // json-server doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
        if (type === DELETE_MANY) {
            return Promise.all(
                params.ids.map(id => httpClient(`${apiUrl}/${resource}/${id}`, options))
            ).then(responses => ({
                data: responses.map(response => response.json)
            }));
        }
        return httpClient(url, options).then(response =>
            convertHTTPResponse(response, type, resource, params)
        );
    };
}

