import RestaurantList from "./RestaurantList";
import RestaurantEdit from "./RestaurantEdit";
import RestaurantCreate from "./RestaurantCreate";
import EmojiFoodBeverageIcon from '@material-ui/icons/EmojiFoodBeverage';

export default {
    list: RestaurantList,
    create: RestaurantCreate,
    edit: RestaurantEdit,
    icon: EmojiFoodBeverageIcon
};