import React, {useState} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Paper from "@material-ui/core/Paper";
import {AxiosPost} from "../../utilities/common";
import {AppConstant} from "../../utilities/constants";
import {useNotify} from 'react-admin';
import {useHistory} from "react-router-dom";
import HueNotification from "../notification/HueNotification";

const images = [
    "https://resources.huetrippal.com/resources/places/5ceba9069373fa376c75422e/images/fe53a957-c941-4869-8ab0-f91f8669203c.jpg"
];

const getRandomArbitrary = (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
};


function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://huetrippal.com/">
                Hue Trippal
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useSignUpStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    image: {
        backgroundImage: 'url(' + images[0] + ')',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        background: '#733000',
        margin: theme.spacing(3, 0, 2),
        "&:hover": {
            //you want this to be the same as the backgroundColor above
            backgroundColor: "#733000"
        }
    },
    root: {
        height: '100vh',
    },
}));

export default function Register() {
    const notify = useNotify();
    const history = useHistory();
    const classes = useSignUpStyles(),
        [email, setEmail] = useState(""),
        [password, setPassword] = useState(""),
        [firstName, setFirstName] = useState(""),
        [lastName, setLastName] = useState(""),
        [phone, setPhone] = useState("")
    ;

    function inputEmail(e) {
        e.preventDefault();
        setEmail(e.target.value)
    }

    function inputPassword(e) {
        e.preventDefault();
        setPassword(e.target.value)
    }

    function inputFirstName(e) {
        e.preventDefault();
        setFirstName(e.target.value)
    }

    function inputLatName(e) {
        e.preventDefault();
        setLastName(e.target.value)
    }

    function inputPhone(e) {
        e.preventDefault();
        setPhone(e.target.value)
    }

    function handleSummit(e) {
        e.preventDefault();
        if (email === "" || password === "" || firstName === "" || lastName === "") {
            notify('Vui lòng nhập đầy đủ thông tin', 'error');
            return
        }
        if (password.length < 8) {
            notify('Mật khẩu phải chứa ít nhất 8 kí tự', 'error');
            return
        }
        if (!/[a-z]/.test(password)) {
            notify('Mật khẩu phải chứa 1 kí tự thường', 'error');
            return
        }
        if (!/[A-Z]/.test(password)) {
            notify('Mật khẩu phải chứa 1 kí tự in hoa', 'error');
            return
        }
        if (!/\d/.test(password)) {
            notify('Mật khẩu phải chứa 1 chữ số', 'error');
            return
        }
        // if (!/[@]/.test(password)) {
        //     notify('Mật khẩu phải chứa 1 kí tự đặc biệt', 'error');
        //     return
        // }
        let url = `${AppConstant.ApiUrl}/users/register`;
        const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!regexp.test(email)) {
            notify('Email không hợp lệ', 'error');
            return
        }
        let request_body = {
            "email": email,
            "password": password,
            "first_name": firstName,
            "last_name": lastName,
            "phone": phone
        };
        AxiosPost('post', url, request_body).then(function (response) {
            if (response.status === 200) {
                notify('Mail xác nhận gửi thành công, vui lòng kiểm tra email', 'info');
                //setTimeout(() => history.push('/#/login'), 3000);
                console.log("Login Response", response);
            }
        }).catch(function (error) {
            if (error) {
                if (error.response.status === 409) {
                    notify('Email đã được sử dụng', 'error');
                    console.log("Error", error.response);
                    return
                }
                if (error.response) {
                    notify('Gửi mail không thành công', 'error');
                    console.log("Error", error.response);
                }
            }
        });
    }


    return (
        <Grid container component="main" className={classes.root}>
            <Grid item xs={false} sm={4} md={7} className={classes.image}/>
            <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square
                  style={{background: 'linear-gradient(to bottom, #ffffff, #bba869)', fontFamily: 'Stay Fresh'}}>
                <Container component="main" maxWidth="xs">
                    <CssBaseline/>
                    <div className={classes.paper}>
                        <Avatar src="/favicon.png" className={classes.avatar}>
                            <LockOutlinedIcon/>
                        </Avatar>
                        <Typography component="h1" variant="h5" style={{fontFamily: "verdana"}}>
                            Đăng Kí
                        </Typography>
                        <form className={classes.form} noValidate>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        autoComplete="fname"
                                        name="firstName"
                                        variant="outlined"
                                        required
                                        fullWidth
                                        id="firstName"
                                        label="Họ"
                                        autoFocus
                                        onChange={inputFirstName}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        variant="outlined"
                                        required
                                        fullWidth
                                        id="lastName"
                                        label="Tên"
                                        name="lastName"
                                        onChange={inputLatName}
                                        autoComplete="lname"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        variant="outlined"
                                        required
                                        fullWidth
                                        id="email"
                                        label="Email"
                                        name="email"
                                        onChange={inputEmail}
                                        autoComplete="email"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        inputProps={{maxLength: 20}}
                                        variant="outlined"
                                        required
                                        fullWidth
                                        name="password"
                                        label="Mật khẩu"
                                        type="password"
                                        id="password"
                                        onChange={inputPassword}
                                        autoComplete="current-password"
                                        placeholder={"Ít nhất 8 kí tự, 1 thường, 1 in hoa, 1 chữ số"}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        name="phone"
                                        label="Số điện thoại"
                                        id="phone"
                                        onChange={inputPhone}
                                    />
                                </Grid>
                            </Grid>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                onClick={(event) => handleSummit(event)}
                                className={classes.submit}
                            >
                                Đăng Kí
                            </Button>
                            <Grid container justify="flex-end">
                                <Grid item>
                                    <Link href="/#/login" variant="body2" style={{color: '#733000'}}>
                                        Đã có tài khoản ? Đăng nhập
                                    </Link>
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                    <Box mt={5}>
                        <Copyright/>
                    </Box>
                </Container>
            </Grid>
            <HueNotification/>
        </Grid>
    );
}