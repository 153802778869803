import {CardActions, SaveButton as ReactAdminSave, Toolbar} from "react-admin";
import React from "react";

const SaveButton = ReactAdminSave;

SaveButton.defaultProps = {undoable: false};

export const EditToolbar = props => (
    <Toolbar {...props} >
        <SaveButton/>
    </Toolbar>
);

export const NoneActions = props => (
    <CardActions/>
);

export const NoButtonToolbar = props => (
    <Toolbar {...props} >
        <NoneActions/>
    </Toolbar>
);
