import React from "react";
import {Datagrid, EditButton, EmailField, List, TextField} from 'react-admin'
import UsersTitle from "./UsersTitle";
import ListActions from "../../actions/lists";
import DeleteButtonWithConfirmation from "../delete_confirmation/DeleteButtonWithConfirmation";

const UsersList = (props) => {
    return (
        <List bulkActionButtons={false} actions={<ListActions hasCreate/>}
              title={<UsersTitle type="Tài khoản"/>} {...props}>
            <Datagrid>
                <EmailField label={"Email"} source={"email"}/>
                <TextField label={"Họ"} source={"first_name"}/>
                <TextField label={"Tên"} source={"last_name"}/>
                <TextField label={"Số điện thoại"} source={"phone"}/>
                <TextField label={"Quyền"} source={"role"}/>
                <EditButton label={""}/>
                <DeleteButtonWithConfirmation undoable={false} label={""}/>
            </Datagrid>
        </List>
    )
};

export default UsersList;