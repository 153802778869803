import * as React from 'react';
import {cloneElement} from 'react';
import {CreateButton, ExportButton, sanitizeListRestProps, TopToolbar,} from 'react-admin';

const ListActions = ({
                         currentSort,
                         className,
                         resource,
                         filters,
                         displayedFilters,
                         exporter, // you can hide ExportButton if exporter = (null || false)
                         filterValues,
                         permanentFilter,
                         hasCreate, // you can hide CreateButton if hasCreate = false
                         basePath,
                         selectedIds,
                         onUnselectItems,
                         showFilter,
                         maxResults,
                         total,
                         ...rest
                     }) => (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
        {filters && cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
        })}
        {hasCreate ? <CreateButton basePath={basePath}/> : null}
        <ExportButton
            disabled={total === 0}
            resource={resource}
            sort={currentSort}
            filter={{...filterValues, ...permanentFilter}}
            exporter={exporter}
            maxResults={maxResults}
        />
        {/* Add your custom actions */}
        {/*<Button*/}
        {/*    onClick={() => {*/}
        {/*        alert('Your custom action');*/}
        {/*    }}*/}
        {/*    label="Show calendar"*/}
        {/*>*/}
        {/*    <IconEvent/>*/}
        {/*</Button>*/}
    </TopToolbar>
);

ListActions.defaultProps = {
    selectedIds: [],
    onUnselectItems: () => null,
};

export default ListActions;