import React from "react";
import {Link, withRouter} from "react-router-dom";
import Button from "@material-ui/core/Button";

const MyProfile = (props) => {
    const id = props.userID;
    if (id) {
        return (
            <Link to={`/users/${id}`}>
                <Button
                    id="buttonLink"
                    style={{
                        marginRight: 25,
                        marginTop: 25,
                        verticalAlign: "middle",
                        textTransform: "none"
                    }}
                    variant="contained"
                    color="primary"
                >
                    Thông tin tài khoản
                </Button>
            </Link>
        )
    } else {
        return <Link to={`/`}>
            <Button
                id="buttonLink"
                style={{
                    marginRight: 25,
                    marginTop: 25,
                    verticalAlign: "middle",
                    textTransform: "none"
                }}
                variant="contained"
                color="primary"
            >
                Thông tin tài khoản
            </Button>
        </Link>
    }
};

export default withRouter(MyProfile)