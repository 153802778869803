import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import HueNotification from "../notification/HueNotification";
import {makeStyles} from "@material-ui/core/styles";
//import {getRandom} from "../../utilities/common";

const images = [
    "https://resources.huetrippal.com/resources/places/5ceba9069373fa376c75422e/images/fe53a957-c941-4869-8ab0-f91f8669203c.jpg"
];

const useSuccessStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
    },
    typography: {
        fontFamily: "verdana",
        fontSize: '1rem'
    },
    image: {
        backgroundImage: 'url(' + images[0] + ')',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        background: '#733000',
        margin: theme.spacing(3, 0, 2),
        "&:hover": {
            //you want this to be the same as the backgroundColor above
            backgroundColor: "#733000"
        }
    },
}));

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://huetrippal.com/">
                HueTrippal
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const SignUpSuccess = () => {
    const classes = useSuccessStyles();

    return (
        <Grid container component="main" className={classes.root}>
            <CssBaseline/>
            <Grid item xs={false} sm={4} md={7} className={classes.image}/>
            <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square
                  style={{background: 'linear-gradient(to bottom, #ffffff, #bba869)', fontFamily: 'Stay Fresh'}}>
                <div className={classes.paper}>
                    <Avatar src="/favicon.png" className={classes.avatar}>
                        <LockOutlinedIcon/>
                    </Avatar>
                    <Typography component="h1" variant="h5" className={classes.typography}>
                        Xác Nhận Email Thành Công
                    </Typography>
                    <Link href="/#/login" variant="body2" style={{color: '#733000', paddingTop: '50px'}}>
                        Quay lại trang đăng nhập
                    </Link>
                    <Copyright/>
                </div>
            </Grid>
            <HueNotification/>
        </Grid>
    );
};

export default SignUpSuccess;