import React, {useState} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import {useStyles} from '../../theme/SignInTheme'
import {connect} from 'react-redux';
import {showNotification, userLogin} from 'react-admin';
import HueNotification from "../notification/HueNotification";
import './facebook.css'
import firebase from "firebase/app";
import 'firebase/auth';
import '../facebook_auth/facebook.css'
import {AppConstant} from "../../utilities/constants";
import styled from 'styled-components';

const Wrapper = styled.div`
    @media only screen and (max-width : 399px) {
        width: 10%
    }
`;

const BtnFacebook = styled.button`
    width: 165px;
    height:35px;  
    border-radius: 4px;
    background: #3b5998;
    color:white;
    border:0px transparent;  
    text-align: center;
    margin:5px;
    display: inline-block;

    &:hover{
        background: #3b5998;
        opacity: 0.6;
    }
`;
const BtnGoogle = styled.button`
    margin:5px;
    width: 165px;
    height:35px;
    border-radius: 4px;
    background: #db3236;
    color:white;
    border:0px transparent;
    text-align: center;

    &:hover{
        background: #3b5998;
        opacity: 0.6;
    }
`;

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://huetrippal.com/">
                HueTrippal
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const CustomLogin = (props) => {
    if (!firebase.apps.length) {
        firebase.initializeApp(AppConstant.FirebaseConfig);
    }
    const classes = useStyles();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [token, setToken] = useState("");

    const firebaseAuth = (provider) => {
        firebase
            .auth()
            .signInWithPopup(provider)
            .then((result) => {
                // This gives you a Facebook Access Token. You can use it to access the Facebook API.
                console.log("Result", result);
                if (result.credential.accessToken) {
                    const credentials = {
                        facebookToken: result.credential.accessToken,
                    };
                    props.userLogin(credentials);
                } else {
                    props.showNotification("error login with facebook");
                    return Promise.reject()
                }
            })
            .catch((error) => {
                let errorMessage = error.message;
                console.log(error)
                props.showNotification(errorMessage, 'warning')
            });
    };

    function inputEmail(e) {
        e.preventDefault();
        setEmail(e.target.value)
    }

    function inputPassword(e) {
        e.preventDefault();
        setPassword(e.target.value)
    }

    const summitCredentials = (e) => {
        e.preventDefault();
        if (email === '' || password === '') {
            props.showNotification("email or password can not be empty");
            return
        }
        const credentials = {
            email: email,
            password: password,
        };
        props.userLogin(credentials);
    };

    const handleFacebookLogin = (event) => {
        event.preventDefault();
        let provider = new firebase.auth.FacebookAuthProvider();
        firebaseAuth(provider);
    };

    return (
        <Grid container component="main" className={classes.root}>
            <CssBaseline/>
            <Grid item xs={false} sm={4} md={7} className={classes.image}/>
            <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square
                  style={{background: 'linear-gradient(to bottom, #ffffff, #bba869)', fontFamily: 'Stay Fresh'}}>
                <div className={classes.paper}>
                    <Avatar src="/favicon.png" className={classes.avatar}>
                        <LockOutlinedIcon/>
                    </Avatar>
                    <Typography component="h1" variant="h5" className={classes.typography}>
                        Đăng Nhập
                    </Typography>
                    <form className={classes.form} noValidate>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email"
                            name="email"
                            onChange={inputEmail}
                            autoComplete="email"
                            autoFocus
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Mật khẩu"
                            onChange={inputPassword}
                            type="password"
                            id="password"
                            autoComplete="current-password"
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={(event) => {
                                summitCredentials(event)
                            }}
                        >
                            Đăng Nhập
                        </Button>
                        <button type="button" className="fb connect" onClick={(event) => {
                            handleFacebookLogin(event)
                        }}>
                            Đăng nhập bằng Facebook
                        </button>
                        <Grid container>
                            <Grid item xs>
                                <Link href="/#/forgot-password" variant="body2" style={{color: '#733000'}}>
                                    Quên mật khẩu ?
                                </Link>
                            </Grid>
                            <Grid item>
                                <Link href="/#/register" variant="body2" style={{color: '#733000'}}>
                                    Chưa có tài khoản ? Đăng Kí
                                </Link>
                            </Grid>
                        </Grid>
                        <Box mt={5}>
                            <Copyright/>
                        </Box>
                    </form>
                </div>
            </Grid>
            <HueNotification/>
        </Grid>
    );
};

export default connect(undefined, {userLogin, showNotification})(CustomLogin);