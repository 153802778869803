import React from "react";
import {Datagrid, EditButton, EmailField, List, TextField} from 'react-admin'
import RestaurantTitle from "./RestaurantTitle";
import ListActions from "../../actions/lists";
import DeleteButtonWithConfirmation from "../delete_confirmation/DeleteButtonWithConfirmation";

const RestaurantList = (props) => {
    return (
        <List bulkActionButtons={false} actions={<ListActions hasCreate/>}
              title={<RestaurantTitle type="Ăn Uống"/>} {...props}>
            <Datagrid>
                <TextField label={"Tên cơ sở"} source={"title"}/>
                <EmailField label={"Email"} source={"email"}/>
                <TextField label={"Địa chỉ"} source={"address"}/>
                <TextField label={"Số điện thoại"} source={"phone"}/>
                <EditButton label={""}/>
                <DeleteButtonWithConfirmation undoable={false} label={""}/>
            </Datagrid>
        </List>
    )
};

export default RestaurantList;