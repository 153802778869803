import React from "react";
import {
    CheckboxGroupInput,
    Create,
    email,
    FormDataConsumer,
    RadioButtonGroupInput,
    required,
    showNotification,
    SimpleForm,
    TextInput,
    userLogin
} from "react-admin";
import ShoppingTitle from "./ShoppingTitle";
import {connect} from 'react-redux';
import {AppConstant, ShoppingForm} from "../../utilities/constants";
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {TimeInput} from "react-admin-date-inputs2";
import viLocale from "date-fns/locale/vi";

// const validateStars = [minValue(0), maxValue(5)];

const ShoppingCreate = (props) => {
    return (
        <Create title={<ShoppingTitle type="Tạo"/>} {...props}>
            <SimpleForm>
                <TextInput label={"Tên cơ sở"} source={"title"} validate={required()} multiline={true}
                           fullWidth={true}/>
                <TextInput label={"Địa chỉ"} source={"address"} validate={required()} multiline={true}
                           fullWidth={true}/>
                <TextInput type="email" label={"Email"} source={"email"} validate={[required(), email()]}
                           multiline={true}
                           fullWidth={true}/>
                <TextInput label={"Số điện thoại"} source={"phone"} validate={required()} multiline={true}
                           fullWidth={true}/>
                <TextInput label={"Website"} source={"website"} multiline={true} fullWidth={true}/>
                <TextInput label={"Mô tả cơ sở"} source={"description"} multiline={true} fullWidth={true}/>
                <RadioButtonGroupInput label={"Loại cơ sở kink doanh"} source="business_type"
                                       choices={ShoppingForm.BusinessType}
                                       validate={required()}/>
                <CheckboxGroupInput label={"Tiện ích"} source="benefit"
                                    choices={ShoppingForm.Benefit} row={false}
                                    style={{paddingTop: '10px', fontWeight: '100px'}}/>
                <TextInput label={"Tag Tìm Kiếm"} source={"search_tag"} multiline={true} fullWidth={true}/>
                <FormDataConsumer>
                    {({formData, ...rest}) => {
                        return (
                            <div>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <TimeInput validate={required()}
                                               source="open_time.start_time"
                                               label="Giờ mở cửa"
                                               options={{
                                                   fullWidth: true,
                                                   format: AppConstant.OpenTimeFormat,
                                                   //format: 'hh:mm',
                                                   ampm: true,
                                                   clearable: true
                                               }}
                                               providerOptions={{
                                                   utils: DateFnsUtils,
                                                   locale: viLocale
                                               }}/>
                                    <TimeInput validate={required()}
                                               source="open_time.end_time"
                                               label="Giờ đóng cửa"
                                               options={{
                                                   fullWidth: true,
                                                   format: AppConstant.OpenTimeFormat,
                                                   //format: 'hh:mm',
                                                   ampm: true,
                                                   clearable: true
                                               }}
                                               providerOptions={{
                                                   utils: DateFnsUtils,
                                                   locale: viLocale
                                               }}/>
                                </MuiPickersUtilsProvider>
                            </div>
                        )
                    }}
                </FormDataConsumer>
            </SimpleForm>
        </Create>
    )
};

export default connect(undefined, {userLogin, showNotification})(ShoppingCreate);