import React from "react";
import {Create, PasswordInput, required, SimpleForm, TextInput} from "react-admin";
import UsersTitle from "./UsersTitle";

const UsersCreate = (props) => {
    return (
        <Create title={<UsersTitle type="Create"/>} {...props}>
            <SimpleForm>
                <TextInput label={"Email"} source={"email"} multiline fullWidth={true} validate={required()}/>
                <PasswordInput label={"Password"} source={"password"} multiline validate={required()}/>
                <TextInput label={"First name"} source={"first_name"} multiline fullWidth={true}/>
                <TextInput label={"Last name"} source={"last_name"} multiline fullWidth={true}/>
                <TextInput label={"Phone"} source={"phone"}/>
            </SimpleForm>
        </Create>
    )
};

export default UsersCreate