import ShoppingList from "./ShoppingList";
import RestaurantEdit from "./ShoppingEdit";
import RestaurantCreate from "./ShoppingCreate";
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';

export default {
    list: ShoppingList,
    create: RestaurantCreate,
    edit: RestaurantEdit,
    icon: ShoppingBasketIcon
};