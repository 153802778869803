import React from "react";
import {
    ArrayInput,
    CheckboxGroupInput,
    Edit,
    FormDataConsumer,
    FormTab,
    ImageField,
    ImageInput,
    maxValue,
    minValue,
    NumberInput,
    RadioButtonGroupInput,
    required,
    SimpleFormIterator,
    TabbedForm,
    TextInput,
    useNotify,
    withDataProvider
} from 'react-admin';
import ResidenceTitle from "./ResidenceTitle";
import {EditToolbar} from "../toolbar/ToolBar";
import {AppConstant, ResidenceForm} from "../../utilities/constants";
import {styles} from "../../utilities/styleField";
import ShowMapHook from "../maps/ShowMapHook";
import ImageViewer from "../image_viewer/ImageViewer";


const validateStars = [minValue(0), maxValue(5)];
const validateLongitude = [minValue(-180), maxValue(180)];
const validateLatitude = [minValue(-90), maxValue(90)];

const ResidenceEdit = (props) => {
    const notify = useNotify();
    return (
        <Edit title={<ResidenceTitle type={"Sửa"}/>} {...props}>
            <TabbedForm redirect={false} toolbar={<EditToolbar/>}>
                <FormTab label={"Vị trí bản đồ"}>
                    <FormDataConsumer>
                        {({formData, ...rest}) => {
                            if (formData.location) {
                                return (
                                    <div>
                                        <NumberInput
                                            label="Kinh độ"
                                            source="location.coordinates.long"
                                            style={styles.inputInline}
                                            validate={validateLongitude}
                                        />
                                        <NumberInput
                                            label="Vĩ độ"
                                            source="location.coordinates.lat"
                                            style={{marginLeft: 50}}
                                            validate={validateLatitude}
                                        />
                                        <ShowMapHook location={formData.location}/>
                                    </div>
                                );
                            } else {
                                return (
                                    <div>
                                        <NumberInput
                                            label="Kinh độ"
                                            source="location.coordinates.long"
                                            style={styles.inputInline}
                                            validate={validateLongitude}
                                        />
                                        <NumberInput
                                            label="Vĩ độ"
                                            source="location.coordinates.lat"
                                            style={{marginLeft: 50}}
                                            validate={validateLatitude}
                                        />
                                    </div>
                                );
                            }
                        }}
                    </FormDataConsumer>
                </FormTab>
                <FormTab label={"Thông tin chung"}>
                    <TextInput label={"Tên cơ sở"} source={"title"} validate={required()} multiline={true}
                               fullWidth={true}/>
                    <RadioButtonGroupInput label={"Loại cơ sở"} source="business_type"
                                           choices={ResidenceForm.BusinessType}
                                           validate={required()}/>
                    <TextInput label={"Địa chỉ"} source={"address"} validate={required()} multiline={true}
                               fullWidth={true}/>
                    <TextInput label={"Số điện thoại"} source={"phone"} validate={required()} multiline={true}
                               fullWidth={true}/>
                    <TextInput label={"Website"} source={"website"} multiline={true} fullWidth={true}/>
                    <TextInput label={"Kênh Booking"} source={"booking_channel"} multiline={true} fullWidth={true}/>
                    <TextInput label={"Mô tả cơ sở"} source={"description"} multiline={true} fullWidth={true}/>
                    <RadioButtonGroupInput label={"Loại phòng"} source="room_type"
                                           choices={ResidenceForm.RoomType}
                                           validate={required()}/>
                    <NumberInput label={"Hạng khách sạn"} source={"stars"} validate={validateStars}/>
                    <TextInput label={"Tag Tìm Kiếm"} source={"search_tag"} multiline={true} fullWidth={true}/>
                </FormTab>
                <FormTab label="Hình ảnh">
                    <ArrayInput source="images" style={styles.images}>
                        <SimpleFormIterator disableAdd>
                            <FormDataConsumer>
                                {({formData, scopedFormData, getSource, ...rest}) => {
                                    if (scopedFormData && scopedFormData.url) {
                                        let image = new Image();
                                        let imageUrl = '';
                                        if (scopedFormData.url.includes(AppConstant.ResourcesUrl) || scopedFormData.url.includes('blob:')) {
                                            imageUrl = scopedFormData.url
                                        } else {
                                            imageUrl = `${AppConstant.ResourcesUrl}${scopedFormData.url}`
                                        }
                                        image.src = imageUrl;
                                        return (
                                            <div>
                                                <ImageViewer image={image} imageUrl={imageUrl}
                                                             width={AppConstant.ImageWidth}
                                                             height={AppConstant.ImageHeight}/>
                                            </div>
                                        );
                                    }
                                }}
                            </FormDataConsumer>
                        </SimpleFormIterator>
                    </ArrayInput>
                    <ImageInput source="images" label="Nhập ảnh" accept="image/*" multiple
                                maxSize={AppConstant.MaxImageSize} options={{
                        onDropRejected(files, event) {
                            notify(`Kích thước mỗi hình không lớn hơn ${AppConstant.MaxImageSize / Math.pow(1024, 2)} MB`, "error")
                        }
                    }}>
                        <ImageField style={{display: "none"}} source="url" title=""/>
                    </ImageInput>
                </FormTab>
                <FormTab label={'Giá phòng và Chính sách'}>
                    <NumberInput label={'Giá phòng'} source={"price"} validate={[required(), minValue(0)]}/>
                    <TextInput label={'Các cơ sở kinh doanh có ưu đãi đặc biệt'} source={"special_offers"}
                               fullWidth={true} multiline={true}/>
                    <CheckboxGroupInput label={'Chính sách'} source="price_policy"
                                        choices={ResidenceForm.PricePolicy}
                                        style={{paddingTop: '20px'}} row={false}/>
                </FormTab>
                <FormTab label={'Tiện nghi chung'}>
                    <CheckboxGroupInput label={''} source="common_benefit"
                                        choices={ResidenceForm.CommonBenefit}
                                        style={{paddingTop: '20px'}} row={false}/>
                </FormTab>
                <FormTab label={'Tiện nghi trong nhà'}>
                    <CheckboxGroupInput label={''} source="indoor_benefit"
                                        choices={ResidenceForm.IndoorBenefit}
                                        style={{paddingTop: '20px'}} row={false}/>
                </FormTab>
                <FormTab label={'Phong cách'}>
                    <CheckboxGroupInput label={""} source="style"
                                        choices={ResidenceForm.Style}
                                        style={{paddingTop: '20px'}} row={false}/>
                </FormTab>
                <FormTab label={'Ngôn ngữ'}>
                    <FormDataConsumer>
                        {({formData, ...rest}) => {
                            console.log('LANGUAGES', formData.languages);
                            if (formData && formData.languages) {
                                if (formData.languages.includes('Khác')) {
                                    return (
                                        <div>
                                            <CheckboxGroupInput label={"Ngôn ngữ"} source="languages"
                                                                choices={ResidenceForm.Languages} row={false}/>
                                            <TextInput label={"Ngôn ngữ khác"} source={"other_languages"}
                                                       fullWidth={true}
                                                       multiline={true} validate={required()}/>
                                        </div>
                                    )
                                }
                            }
                            return (
                                <CheckboxGroupInput label={"Ngôn ngữ"} source="languages"
                                                    choices={ResidenceForm.Languages}
                                                    style={{paddingTop: '10px'}}
                                                    row={false}/>
                            )
                        }}
                    </FormDataConsumer>
                </FormTab>
            </TabbedForm>
        </Edit>
    )
};

export default withDataProvider(ResidenceEdit)