import React from 'react';
import {Route} from 'react-router-dom';
import SignUp from "./components/sign_up/SignUp";
import SignUpSuccess from "./components/success/SignUpSuccess";
import SignUpFailure from "./components/failure/SignUpFailure";
import LostPassword from "./components/lost_password/LostPassword";
import ResetPassword from "./components/reset_password/ResetPassword";

export default [
    <Route path="/register" component={SignUp} noLayout/>,
    <Route path="/signup/success" component={SignUpSuccess} noLayout/>,
    <Route path="/signup/failure" component={SignUpFailure} noLayout/>,
    <Route path="/forgot-password" component={LostPassword} noLayout/>,
    <Route path="/reset-password" component={ResetPassword} noLayout/>,
];