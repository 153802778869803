import React from "react";
import residence from '../residence'
import restaurants from '../restaurants'
import shopping from '../shopping'
import users from '../users'
import {Resource} from "react-admin";
import {AppConstant} from "../../utilities/constants";
import UsersEdit from "../users/UsersEdit";

const displayLabel = name => ({label: name});

export const ShowResources = (permissions) => {
    return (
        [
            permissions === AppConstant.Administrator ? <Resource
                name={AppConstant.Routes.Users}
                options={displayLabel("Tài khoản")}
                {...users}
            /> : <Resource
                name={AppConstant.Routes.Users}
                edit={UsersEdit}
            />,
            <Resource
                name={AppConstant.Routes.Residences}
                options={displayLabel("Lưu Trú")}
                {...residence}
            />,
            <Resource
                name={AppConstant.Routes.Restaurants}
                options={displayLabel("Ăn Uống")}
                {...restaurants}
            />,
            <Resource
                name={AppConstant.Routes.Shopping}
                options={displayLabel("Mua Sắm")}
                {...shopping}
            />,
        ]
    )
};