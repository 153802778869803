export const AppConstant = {
    ApiUrl: process.env.REACT_APP_MODE === 'local' ? process.env.REACT_APP_LOCAL_API_URL : process.env.REACT_APP_PROD_API_URL,
    ResourcesUrl: process.env.REACT_APP_RESOURCES_API_URL,
    Routes: {
        Users: 'users',
        Residences: 'residences',
        Restaurants: 'restaurants',
        BenefitTypes: 'benefitTypes',
        Shopping: 'shopping'
    },
    MaxMapZoom: 1000,
    BenefitPage: 1,
    BenefitPerPage: 500,
    DefaultLocation: [16.46772, 107.57914],
    Administrator: 'administrator',
    ImageWidth: 170,
    ImageHeight: 130,
    FirebaseConfig: {
        apiKey: "AIzaSyCXIldRM3AHrIM31YrSUp45tu-kA1-zGFg",
        authDomain: "hue-trippal-doanh-nghiep.firebaseapp.com",
        projectId: "hue-trippal-doanh-nghiep",
        storageBucket: "hue-trippal-doanh-nghiep.appspot.com",
        messagingSenderId: "758704964350",
        appId: "1:758704964350:web:477809a2eaa7f1a10c5baf",
        measurementId: "G-NZKTWXKR9P"
    },
    TimeFormat: 'EE dd-MM-yyyy hh:mm a',
    OpenTimeFormat: 'hh:mm a',
    MaxImageSize: 15 * Math.pow(1024, 2), // in bytes
};

export const ResidenceForm = {
    BusinessType: [
        {
            id: 'Khách sạn',
            name: 'Khách sạn'
        },
        {
            id: 'Khách sạn Mini',
            name: 'Khách sạn Mini'
        },
        {
            id: 'Hostel',
            name: 'Hostel'
        },
        {
            id: 'Khu nghỉ dưỡng', name: 'Khu nghỉ dưỡng'
        },
        {
            id: 'Homestay nguyên nhà',
            name: 'Homestay nguyên nhà'
        },
        {
            id: 'Homstay phòng riêng',
            name: 'Homstay phòng riêng'
        },
        {
            id: 'Căn hộ',
            name: 'Căn hộ'
        }],
    CommonBenefit: [
        {
            id: "Bao gồm bữa sáng",
            name: "Bao gồm bữa sáng"
        },
        {
            id: "Wifi miễn phí",
            name: "Wifi miễn phí"
        },
        {
            id: "Phòng hội thảo",
            name: "Phòng hội thảo"
        },
        {
            id: "Quán cà phê",
            name: "Quán cà phê"
        },
        {
            id: "Quán bar",
            name: "Quán bar"
        },
        {
            id: "Sân Thượng",
            name: "Sân Thượng"
        },
        {
            id: "Khu dịch vụ trên sân thượng",
            name: "Khu dịch vụ trên sân thượng"
        },
        {
            id: "Spa",
            name: "Spa"
        },
        {
            id: "Nhà Hàng",
            name: "Nhà hàng"
        },
        {
            id: "Phòng suite",
            name: "Phòng suite"
        },
        {
            id: "Khu vui chơi trẻ em",
            name: "Khu vui chơi trẻ em"
        },
        {
            id: "Bể bơi ngoài trời",
            name: "Bể bơi ngoài trời"
        },
        {
            id: "Bể bơi trong nhà",
            name: "Bể bơi trong nhà"
        },
        {
            id: "Phòng GYM",
            name: "Phòng GYM"
        },
        {
            id: "Casino",
            name: "Casino"
        },
        {
            id: "Sân tennis",
            name: "Sân tennis"
        },
        {
            id: "Thân thiện với vật nuôi",
            name: "Thân thiện với vật nuôi"
        },
        {
            id: "Cho thuê xe đạp",
            name: "Cho thuê xe đạp"
        },
        {
            id: "Cho thuê xe máy",
            name: "Cho thuê xe máy"
        },
        {
            id: "Xe đạp miễn phí",
            name: "Xe đạp miễn phí"
        },
        {
            id: "Xe đưa đón miễn phí",
            name: "Xe đưa đón miễn phí"
        },
        {
            id: "Dịch vụ xe buýt sân bay",
            name: "Dịch vụ xe buýt sân bay"
        },
        {
            id: "Chỗ đỗ xe miễn phí",
            name: "Chỗ đỗ xe miễn phí"
        },
        {
            id: "Có lối đi cho xe lăn",
            name: "Có lối đi cho xe lăn"
        }
    ],
    Style: [
        {
            id: 'Bình dân',
            name: 'Bình dân'
        },
        {
            id: 'Hạng trung',
            name: 'Hạng trung'
        },
        {
            id: 'Sang trọng',
            name: 'Sang trọng'
        },
        {
            id: 'Dành cho gia đình',
            name: 'Dành cho gia đình'
        },
        {
            id: 'Hiện đại',
            name: 'Hiện đại'
        },
        {
            id: 'Lãng mạn',
            name: 'Lãng mạn'
        },
        {
            id: 'Thương gia',
            name: 'Thương gia'
        },
        {
            id: 'Cổ kính',
            name: 'Cổ kính'
        },
        {
            id: 'View đẹp',
            name: 'View đẹp'
        }
    ],
    Languages: [
        {
            id: 'Việt',
            name: 'Việt'
        },
        {
            id: 'Anh',
            name: 'Anh'
        },
        {
            id: 'Pháp',
            name: 'Pháp'
        },
        {
            id: 'TBN',
            name: 'TBN'
        },
        {
            id: 'Trung',
            name: 'Trung'
        },
        {
            id: 'Khác',
            name: 'Khác'
        }
    ],
    RoomType: [
        {
            id: 'Phòng không hút thuốc',
            name: 'Phòng không hút thuốc'
        },
        {
            id: 'Thông thường',
            name: 'Thông thường'
        },
        {
            id: 'Phòng Suite',
            name: 'Phòng Suite'
        }
    ],
    IndoorBenefit: [
        {
            id: 'Điều hòa nhiệt độ',
            name: 'Điều hòa nhiệt độ'
        },
        {
            id: 'Truyền hình cáp/Truyền hình vệ tinh',
            name: 'Truyền hình cáp/Truyền hình vệ tinh'
        },
        {
            id: 'Bàn làm việc',
            name: 'Bàn làm việc'
        },
        {
            id: 'Két sắt',
            name: 'Két sắt'
        },
        {
            id: 'Điện thoại để bàn',
            name: 'Điện thoại để bàn'
        },
        {
            id: 'Máy pha cà phê/trà',
            name: 'Máy pha cà phê/trà'
        },
        {
            id: 'Quầy bar mini',
            name: 'Quầy bar mini'
        },
        {
            id: 'TV màn hình phẳng',
            name: 'TV màn hình phẳng'
        },
        {
            id: 'Nước/Trà/Cà phê miễn phí',
            name: 'Nước/Trà/Cà phê miễn phí'
        },
        {
            id: 'Móc treo quần áo',
            name: 'Móc treo quần áo'
        },
        {
            id: 'Dịch vụ đánh thức / đồng hồ báo thức',
            name: 'Dịch vụ đánh thức / đồng hồ báo thức'
        },
        {
            id: 'Tủ lạnh',
            name: 'Tủ lạnh'
        },
        {
            id: 'Đồ dùng nhà tắm',
            name: 'Đồ dùng nhà tắm'
        },
        {
            id: 'Máy sấy tóc',
            name: 'Máy sấy tóc'
        },
        {
            id: 'Khu nấu ăn',
            name: 'Khu nấu ăn'
        },
        {
            id: 'Dụng cụ làm bếp và bát dĩa',
            name: 'Dụng cụ làm bếp và bát dĩa'
        },
        {
            id: 'Máy giặt',
            name: 'Máy giặt'
        },
        {
            id: 'Bình chữa cháy',
            name: 'Bình chữa cháy'
        },
        {
            id: 'Túi sơ cứu y tế',
            name: 'Túi sơ cứu y tế'
        },
        {
            id: 'Camera an ninh',
            name: 'Camera an ninh'
        },
        {
            id: 'Thiết bị báo cháy',
            name: 'Thiết bị báo cháy'
        },
        {
            id: 'Lối đi riêng',
            name: 'Lối đi riêng'
        },
    ],
    PricePolicy: [
        {
            id: 'Hủy miễn phí',
            name: 'Hủy miễn phí'
        },
        {
            id: 'Thanh toán khi nhận phòng',
            name: 'Thanh toán khi nhận phòng'
        },
        {
            id: 'Thanh toán khi đặt phòng',
            name: 'Thanh toán khi đặt phòng'
        }
    ],
};

export const RestaurantForm = {
    CuisineTypes: [
        {
            id: "Món ăn Huế",
            name: "Món ăn Huế"
        },
        {
            id: "Món Việt Nam",
            name: "Món Việt Nam"
        },
        {
            id: "Món châu Á",
            name: "Món châu Á"
        },
        {
            id: "Món châu Âu",
            name: "Món châu Âu"
        }
    ],
    CuisineChoices: [
        {
            id: "Cả đồ mặn và chay",
            name: "Cả đồ mặn và chay"
        },
        {
            id: "Toàn bộ thực đơn chay",
            name: "Toàn bộ thực đơn chay"
        },
        {
            id: "Có món ăn không có gluten",
            name: "Có món ăn không có gluten"
        },
    ],
    RestaurantFeatures: [
        {
            id: "Có sử dụng tiếng Anh",
            name: "Có sử dụng tiếng Anh"
        },
        {
            id: "Có nhận thẻ tín dụng",
            name: "Có nhận thẻ tín dụng"
        },
        {
            id: "Có bữa ăn tự chọn",
            name: "Có bữa ăn tự chọn"
        },
        {
            id: "Có giao đồ ăn tới nhà",
            name: "Có giao đồ ăn tới nhà"
        },
        {
            id: "Có chỗ đỗ xe ô tô",
            name: "Có chỗ đỗ xe ô tô"
        },
        {
            id: "Có phòng riêng",
            name: "Có phòng riêng"
        },
        {
            id: "Có wifi",
            name: "Có wifi"
        },
        {
            id: "Có nhạc sống",
            name: "Có nhạc sống"
        },
        {
            id: "Có đặt bàn trước",
            name: "Có đặt bàn trước"
        },
        {
            id: "Có yêu cầu tiền boa",
            name: "Có yêu cầu tiền boa"
        },
        {
            id: "Có bán rượu",
            name: "Có bán rượu"
        },
        {
            id: "Cho phép hút thuốc",
            name: "Cho phép hút thuốc"
        },
        {
            id: "Xe lăn có thể di chuyển trong nhà hàng",
            name: "Xe lăn có thể di chuyển trong nhà hàng"
        },
    ],
    Suitableness: [
        {
            id: "Gặp gỡ công việc",
            name: "Gặp gỡ công việc"
        },
        {
            id: "Gia đình có trẻ em",
            name: "Gia đình có trẻ em"
        },
        {
            id: "Các đoàn khách đông",
            name: "Các đoàn khách đông"
        },
        {
            id: "Những sự kiện lớn",
            name: "Những sự kiện lớn"
        },
        {
            id: "Gặp gỡ lãng mạn",
            name: "Gặp gỡ lãng mạn"
        },
        {
            id: "Gặp gỡ kín đáo",
            name: "Gặp gỡ kín đáo"
        },
    ]
};

export const ShoppingForm = {
    BusinessType: [
        {
            id: 'Cửa hàng bán đặc sản',
            name: 'Cửa hàng bán đặc sản'
        },
        {
            id: 'Tiệm may áo dài',
            name: 'Tiệm may áo dài'
        },
        {
            id: 'Cửa hàng bán đồ lưu niệm',
            name: 'Cửa hàng bán đồ lưu niệm'
        },
        {
            id: 'TIệm may đo',
            name: 'TIệm may đo'
        }
    ],
    Benefit: [
        {
            id: "Có chỗ đỗ ô tô",
            name: "Có chỗ đỗ ô tô"
        },
        {
            id: "Có đóng gói mang đi",
            name: "Có đóng gói mang đi"
        },
        {
            id: "Có bán hàng qua điện thoại",
            name: "Có bán hàng qua điện thoại"
        },
        {
            id: "Thanh toán bằng thẻ tín dụng và ví điện tử",
            name: "Thanh toán bằng thẻ tín dụng và ví điện tử"
        },
    ]
}