import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import 'leaflet/dist/leaflet.css';
import './mapLeaflet.css';
import {MapContainer, Marker, Popup, TileLayer, useMap} from "react-leaflet";
import IconColor from "./constants";
import {connect} from 'react-redux'
import {useForm} from 'react-final-form';

/**
 * @return {null}
 */
function ChangeView({center, zoom}) {
    const map = useMap();
    map.setView(center, zoom);
    return null;
}

const ShowMapHook = (props) => {
    const form = useForm();
    const {location} = props;
    const [center, setCenter] = useState({
        lat: location.coordinates.lat,
        lng: location.coordinates.long
    });
    const [marker, setMarker] = useState({
        lat: location.coordinates.lat,
        lng: location.coordinates.long
    });
    const [zoom, setZoom] = useState(15);
    const [draggable, setDraggable] = useState(true);
    const refmarker = useRef(null);
    const toggleDraggable = useCallback(() => {
        setDraggable(!draggable)
    }, [draggable]);

    const handleUpdate = useCallback((marker) => {
        form.change('location.coordinates.lat', marker.lat);
        form.change('location.coordinates.long', marker.lng);
        setMarker({
            lat: marker.lat,
            lng: marker.lng
        });
        setCenter({
            lat: marker.lat,
            lng: marker.lng
        });
    }, [form]);

    useEffect(() => {
        if (location) {
            setMarker({
                lat: location.coordinates.lat,
                lng: location.coordinates.long
            });
            setCenter({
                lat: location.coordinates.lat,
                lng: location.coordinates.long
            });
        }
    }, [location]);

    const updatePosition = useMemo(
        () => ({
            dragend() {
                const marker = refmarker.current;
                setZoom(refmarker.current._map._zoom);
                if (marker) {
                    const updatedMarker = marker.getLatLng();
                    if (updatedMarker) {
                        handleUpdate(updatedMarker);
                    }
                }
            },
            draggable() {
                toggleDraggable()
            }
        }),
        [handleUpdate, toggleDraggable],
    );

    return (
        <div className="map">
            <MapContainer center={[center.lat, center.lng]} zoom={zoom}>
                <ChangeView center={[center.lat, center.lng]} zoom={zoom}/>
                <TileLayer
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Marker
                    draggable={draggable}
                    eventHandlers={updatePosition}
                    position={[marker.lat, marker.lng]}
                    ref={refmarker}
                    icon={IconColor('green')}
                >
                    >
                    <Popup minWidth={140}>
                          <span onClick={toggleDraggable}>
                              Current Location
                              <br/>
                              {draggable
                                  ? "Type: Draggable Marker"
                                  : "Type: Fixed Marker"}
                          </span>
                    </Popup>
                </Marker>
            </MapContainer>
        </div>
    )
};

export default connect(null, null)(ShowMapHook)