import {makeStyles} from "@material-ui/core/styles";
import {getRandom} from "../utilities/common";

const images = [
    "https://resources.huetrippal.com/resources/places/5ceba9069373fa376c75422e/images/fe53a957-c941-4869-8ab0-f91f8669203c.jpg"
];

export const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
    },
    typography: {
        fontFamily: "verdana"
    },
    image: {
        backgroundImage: 'url(' + images[0] + ')',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        background: '#733000',
        margin: theme.spacing(3, 0, 2),
        "&:hover": {
            //you want this to be the same as the backgroundColor above
            backgroundColor: "#733000"
        }
    },
}));

