import React from "react";
import {Datagrid, EditButton, List, TextField} from 'react-admin'
import ResidenceTitle from "./ResidenceTitle";
import ListActions from "../../actions/lists";
import DeleteButtonWithConfirmation from "../delete_confirmation/DeleteButtonWithConfirmation";

const ResidenceList = (props) => {
    return (
        <List bulkActionButtons={false} actions={<ListActions hasCreate/>}
              title={<ResidenceTitle type="Lưu Trú"/>} {...props}>
            <Datagrid>
                <TextField label={"Tên cơ sở"} source={"title"}/>
                <TextField label={"Địa chỉ"} source={"address"}/>
                <TextField label={"Số điện thoại"} source={"phone"}/>
                <TextField label={"Loại cơ sở kinh doanh"} source={"business_type"}/>
                <TextField label={"Hạng khách sạn"} source={"stars"}/>
                <TextField label={"Giá phòng"} source={"price"}/>
                <EditButton label={""}/>
                <DeleteButtonWithConfirmation undoable={false} label={""}/>
            </Datagrid>
        </List>
    )
};

export default ResidenceList;