import React from "react";
import {
    ArrayInput,
    CheckboxGroupInput,
    Edit,
    email,
    FormDataConsumer,
    FormTab,
    ImageField,
    ImageInput,
    maxValue,
    minValue,
    NumberInput,
    RadioButtonGroupInput,
    required,
    SimpleFormIterator,
    TabbedForm,
    TextInput,
    useNotify,
    withDataProvider
} from 'react-admin';
import ShoppingTitle from "./ShoppingTitle";
import {EditToolbar} from "../toolbar/ToolBar";
import {AppConstant, ShoppingForm} from "../../utilities/constants";
import {styles} from "../../utilities/styleField";
import ShowMapHook from "../maps/ShowMapHook";
import {TimeInput} from 'react-admin-date-inputs2';
import DateFnsUtils from "@date-io/date-fns";
import viLocale from "date-fns/locale/vi";
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import ImageViewer from "../image_viewer/ImageViewer";

const validateStars = [minValue(0), maxValue(5)];
const validateLongitude = [minValue(-180), maxValue(180)];
const validateLatitude = [minValue(-90), maxValue(90)];


const ShoppingEdit = (props) => {
    const notify = useNotify();
    const validateOpenTime = (value) => {
        if (value) {
            if (!value) {
                return
            }
            if (!value.start_time || !value.end_time) {
                return
            }
            if (Date.parse(value.start_time) > Date.parse(value.end_time)) {
                notify(`Thời gian đóng cửa lớn hơn mở cửa`, 'error');
                return `Thời gian đóng cửa lớn hơn mở cửa`;
            }
        }
    };
    // const validateEmail = (email) => {
    //     if(email && email !== "") {
    //         const re = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    //         if (!re.test(String(email).toLowerCase())) {
    //             notify(`Email không hợp lệ`, 'error');
    //             return `Email không hợp lệ`;
    //         }
    //     }
    // }
    return (
        <Edit title={<ShoppingTitle type={"Sửa"}/>} {...props}>
            <TabbedForm redirect={false} toolbar={<EditToolbar/>}>
                <FormTab label={"Vị trí bản đồ"}>
                    <FormDataConsumer>
                        {({formData, ...rest}) => {
                            if (formData.location) {
                                return (
                                    <div>
                                        <NumberInput
                                            label="Kinh độ"
                                            source="location.coordinates.long"
                                            style={styles.inputInline}
                                            validate={validateLongitude}
                                        />
                                        <NumberInput
                                            label="Vĩ độ"
                                            source="location.coordinates.lat"
                                            style={{marginLeft: 50}}
                                            validate={validateLatitude}
                                        />
                                        <ShowMapHook location={formData.location}/>
                                    </div>
                                );
                            } else {
                                return (
                                    <div>
                                        <NumberInput
                                            label="Kinh độ"
                                            source="location.coordinates.long"
                                            style={styles.inputInline}
                                            validate={validateLongitude}
                                        />
                                        <NumberInput
                                            label="Vĩ độ"
                                            source="location.coordinates.lat"
                                            style={{marginLeft: 50}}
                                            validate={validateLatitude}
                                        />
                                    </div>
                                );
                            }
                        }}
                    </FormDataConsumer>
                </FormTab>
                <FormTab label={"Thông tin chung"}>
                    <TextInput label={"Tên cơ sở"} source={"title"} validate={required()} multiline={true}
                               fullWidth={true}/>
                    <TextInput label={"Địa chỉ"} source={"address"} validate={required()} multiline={true}
                               fullWidth={true}/>
                    <TextInput type="email" label={"Email"} source={"email"} validate={[required(), email()]}
                               multiline={true}
                               fullWidth={true}/>
                    <TextInput label={"Số điện thoại"} source={"phone"} validate={required()} multiline={true}
                               fullWidth={true}/>
                    <TextInput label={"Website"} source={"website"} multiline={true} fullWidth={true}/>
                    <TextInput label={"Mô tả cơ sở"} source={"description"} multiline={true} fullWidth={true}/>
                    <RadioButtonGroupInput label={"Loại cơ sở kink doanh"} source="business_type"
                                           choices={ShoppingForm.BusinessType}
                                           validate={required()}/>
                    <TextInput label={"Tag Tìm Kiếm"} source={"search_tag"} multiline={true} fullWidth={true}/>
                </FormTab>
                <FormTab label={"Tiện ích"}>
                    <CheckboxGroupInput label={""} source="benefit"
                                        choices={ShoppingForm.Benefit} row={false}
                                        style={{paddingTop: '10px', fontWeight: '100px'}}/>
                </FormTab>
                <FormTab label={"Thời gian"}>
                    <FormDataConsumer>
                        {({formData, ...rest}) => {
                            return (
                                <div>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <TimeInput validate={required()}
                                                   source="open_time.start_time"
                                                   label="Giờ mở cửa"
                                                   options={{
                                                       fullWidth: true,
                                                       format: AppConstant.OpenTimeFormat,
                                                       //format: 'hh:mm',
                                                       ampm: true,
                                                       clearable: true
                                                   }}
                                                   providerOptions={{
                                                       utils: DateFnsUtils,
                                                       locale: viLocale
                                                   }}/>
                                        <TimeInput validate={required()}
                                                   source="open_time.end_time"
                                                   label="Giờ đóng cửa"
                                                   options={{
                                                       fullWidth: true,
                                                       format: AppConstant.OpenTimeFormat,
                                                       //format: 'hh:mm',
                                                       ampm: true,
                                                       clearable: true
                                                   }}
                                                   providerOptions={{
                                                       utils: DateFnsUtils,
                                                       locale: viLocale
                                                   }}/>
                                    </MuiPickersUtilsProvider>
                                </div>
                            )
                        }}
                    </FormDataConsumer>
                </FormTab>
                <FormTab label="Hình ảnh">
                    <ArrayInput source="images" style={styles.images}>
                        <SimpleFormIterator disableAdd>
                            <FormDataConsumer>
                                {({formData, scopedFormData, getSource, ...rest}) => {
                                    if (scopedFormData && scopedFormData.url) {
                                        let image = new Image();
                                        let imageUrl = '';
                                        if (scopedFormData.url.includes(AppConstant.ResourcesUrl) || scopedFormData.url.includes('blob:')) {
                                            imageUrl = scopedFormData.url
                                        } else {
                                            imageUrl = `${AppConstant.ResourcesUrl}${scopedFormData.url}`
                                        }
                                        image.src = imageUrl;
                                        return (
                                            <div>
                                                <ImageViewer image={image} imageUrl={imageUrl}
                                                             width={AppConstant.ImageWidth}
                                                             height={AppConstant.ImageHeight}/>
                                            </div>
                                        );
                                    }
                                }}
                            </FormDataConsumer>
                        </SimpleFormIterator>
                    </ArrayInput>
                    <ImageInput source="images" label="Nhập ảnh" accept="image/*" multiple
                                maxSize={AppConstant.MaxImageSize} options={{
                        onDropRejected(files, event) {
                            notify(`Kích thước mỗi hình không lớn hơn ${AppConstant.MaxImageSize / Math.pow(1024, 2)} MB`, "error")
                        }
                    }}>
                        <ImageField style={{display: "none"}} source="url" title=""/>
                    </ImageInput>
                </FormTab>
                <FormTab label="Bảng Giá">
                    <ArrayInput source="price_table" style={styles.images}>
                        <SimpleFormIterator disableAdd>
                            <FormDataConsumer>
                                {({formData, scopedFormData, getSource, ...rest}) => {
                                    if (scopedFormData && scopedFormData.url) {
                                        let image = new Image();
                                        let imageUrl = '';
                                        if (scopedFormData.url.includes(AppConstant.ResourcesUrl) || scopedFormData.url.includes('blob:')) {
                                            imageUrl = scopedFormData.url
                                        } else {
                                            imageUrl = `${AppConstant.ResourcesUrl}${scopedFormData.url}`
                                        }
                                        image.src = imageUrl;
                                        return (
                                            <div>
                                                <ImageViewer image={image} imageUrl={imageUrl}
                                                             width={AppConstant.ImageWidth}
                                                             height={AppConstant.ImageHeight}/>
                                            </div>
                                        );
                                    }
                                }}
                            </FormDataConsumer>
                        </SimpleFormIterator>
                    </ArrayInput>
                    <ImageInput source="price_table" label="Nhập ảnh" accept="image/*" multiple
                                maxSize={AppConstant.MaxImageSize} options={{
                        onDropRejected(files, event) {
                            notify(`Kích thước mỗi hình không lớn hơn ${AppConstant.MaxImageSize / Math.pow(1024, 2)} MB`, "error")
                        }
                    }}>
                        <ImageField style={{display: "none"}} source="url" title=""/>
                    </ImageInput>
                </FormTab>
            </TabbedForm>
        </Edit>
    )
};

export default withDataProvider(ShoppingEdit)