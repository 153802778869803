import React from "react";
import {Edit, PasswordInput, required, SimpleForm, TextInput} from 'react-admin';
import UsersTitle from "./UsersTitle";
import {EditToolbar} from "../toolbar/ToolBar";

const UsersEdit = (props) => {
    return (
        <Edit title={<UsersTitle type={"Sửa"}/>} {...props}>
            <SimpleForm redirect={false} toolbar={<EditToolbar/>}>
                <TextInput label={"Email"} source={"email"} multiline validate={required()}/>
                <PasswordInput label={"Mật Khẩu mới"} source={"password"}
                               inputProps={{autocomplete: 'current-password'}}/>
                <TextInput label={"Họ"} source={"first_name"} multiline fullWidth={true}/>
                <TextInput label={"Tên"} source={"last_name"} multiline fullWidth={true}/>
                <TextInput label={"Số điện thoại"} source={"phone"}/>
            </SimpleForm>
        </Edit>
    )
};

export default UsersEdit