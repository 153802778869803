import './App.css';
import myDataProvider from "./providers/myDataProvider";
import dashboard from './components/dashboard'
import {AppConstant} from "./utilities/constants";
import {Admin, defaultTheme} from "react-admin";
import authProvider from "./providers/authProvider";
import {ShowResources} from "./components/resources/resources";
import CustomLogin from "./components/login/CustomLogin";
import custom_routes from "./custom_routes";
import React from "react";
import merge from 'lodash/merge';
import red from '@material-ui/core/colors/red';
import indigo from "@material-ui/core/colors/indigo";

const dataProvider = myDataProvider(AppConstant.ApiUrl);

const myTheme = merge({}, defaultTheme, {
    palette: {
        primary: {
            main: '#733000',
        },
        background: {
            default: 'rgba(187,168,105,0.47)',
        },
        secondary: {
            light: '#733000',
            main: '#733000',
            contrastText: '#ffffff',//'#ffffff',
        },
        info: indigo,
        error: red,
        contrastThreshold: 3,
        tonalOffset: 0.2,
    },
    typography: {
        // Use the system font instead of the default Roboto font.
        fontFamily: ['-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Arial', 'sans-serif'].join(','),
    },
    overrides: {
        MuiButton: { // override the styles of all instances of this component
            root: { // Name of the rule
                color: 'black', // Some CSS
            },
        },
    },
});


function App() {
    return (
        <Admin
            theme={myTheme}
            customRoutes={custom_routes}
            dataProvider={dataProvider}
            dashboard={dashboard}
            authProvider={authProvider}
            loginPage={CustomLogin}
        >
            {permissions => ShowResources(permissions)}
        </Admin>
    );
}

export default App;
