import React, {useEffect, useState} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import {useNotify} from 'react-admin';
import HueNotification from "../notification/HueNotification";
import {AxiosPost, getRandom} from "../../utilities/common";
import {makeStyles} from "@material-ui/core/styles";
import {AppConstant} from "../../utilities/constants";
import {useHistory} from "react-router-dom";


const images = [
    "https://resources.huetrippal.com/resources/places/5ceba9069373fa376c75422e/images/4e4ce156-75dd-4d73-b907-caeda2eb3118.jpg",
    "https://resources.huetrippal.com/resources/places/5ceba9069373fa376c75422e/images/4bd641b2-1672-47e2-8962-a8216ddc81a2.JPG",
    "https://resources.huetrippal.com/resources/places/5d834d048f9c3315a4910eae/images/161d6321-bc55-4ea7-a165-3cadd89f97bd.jpg",
    "https://resources.huetrippal.com/resources/places/5d8221c38f9c3311040a7a10/images/d7f08afa-8a86-45ba-9104-bc844e2c1a25.jpg",
    "https://resources.huetrippal.com/resources/places/5d8221c38f9c3311040a7a10/images/a5335e9a-ed23-40f5-83c6-a85a0bb31a08.jpg"
];

const useResetPasswordStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
    },
    typography: {
        fontFamily: "verdana",
        fontSize: '1rem'
    },
    typography_title: {
        padding: '10px 0 0 0',
        fontFamily: "verdana",
        fontSize: '0.9rem'
    },
    image: {
        backgroundImage: 'url(' + images[getRandom(0, 4)] + ')',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        background: '#733000',
        margin: theme.spacing(3, 0, 2),
        "&:hover": {
            //you want this to be the same as the backgroundColor above
            backgroundColor: "#733000"
        }
    },
    resend: {
        background: '#733000',
        margin: theme.spacing(0, 0, 2),
        "&:hover": {
            //you want this to be the same as the backgroundColor above
            backgroundColor: "#733000"
        },
        textTransform: 'none'
    },
}));

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://huetrippal.com/">
                HueTrippal
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const ResetPassword = (props) => {
    const notify = useNotify();
    const history = useHistory();
    const [newPassword, setNewPassword] = useState('');
    const [code, setCode] = useState('');
    const [isDisable, setIsDisable] = useState(false);

    const [initialTime, setInitialTime] = useState(0);
    const [startTimer, setStartTimer] = useState(false);

    const handleOnClick = (event) => {
        event.preventDefault();
        let url = `${AppConstant.ApiUrl}/${AppConstant.Routes.Users}/forgot-password`;
        let request_body = {
            "email": localStorage.getItem("current_email"),
        };
        AxiosPost('post', url, request_body).then(function (response) {
            if (response.status === 200) {
                notify('Mã xác nhận gửi thành công, vui lòng kiểm tra email', 'info');
                setInitialTime(60);
                setStartTimer(true);
                setIsDisable(true);
            }
        }).catch(function (error) {
            if (error) {
                if (error.response) {
                    notify('Gửi mail không thành công', 'error');
                    console.log("Error", error.response);
                }
            }
        });
    };

    useEffect(() => {
        if (initialTime > 0) {
            setTimeout(() => {
                //console.log("startTime, ", initialTime);
                setInitialTime(initialTime - 1);
            }, 1000);
        }

        if (initialTime === 0 && startTimer) {
            //console.log("done");
            setStartTimer(false);
            setIsDisable(false);
        }
    }, [initialTime, startTimer]);

    const inputCode = (event) => {
        event.preventDefault();
        setCode(event.target.value);
    };

    const inputNewPassword = (event) => {
        event.preventDefault();
        setNewPassword(event.target.value);
    };

    const summitResetPassword = (event) => {
        event.preventDefault();
        let url = `${AppConstant.ApiUrl}/${AppConstant.Routes.Users}/reset-password`;
        if (newPassword.length < 8) {
            notify('Mật khẩu phải chứa ít nhất 8 kí tự', 'error');
            return
        }
        if (!/[a-z]/.test(newPassword)) {
            notify('Mật khẩu phải chứa 1 kí tự thường', 'error');
            return
        }
        if (!/[A-Z]/.test(newPassword)) {
            notify('Mật khẩu phải chứa 1 kí tự in hoa', 'error');
            return
        }
        if (!/\d/.test(newPassword)) {
            notify('Mật khẩu phải chứa 1 chữ số', 'error');
            return
        }
        let request_body = {
            "code": code,
            "email": localStorage.getItem("current_email"),
            "new_password": newPassword
        };
        AxiosPost('post', url, request_body).then(function (response) {
            if (response.status === 200) {
                notify('Thay đổi mật khẩu thành công', 'info');
                history.push('/reset-password');
                console.log("Login Response", response);
            }
        }).catch(function (error) {
            if (error) {
                if (error.response) {
                    notify('Thay đổi mật khẩu thất bại', 'error');
                    console.log("Error", error.response);
                }
            }
        });
    };

    const classes = useResetPasswordStyles();

    return (
        <Grid container component="main" className={classes.root}>
            <CssBaseline/>
            <Grid item xs={false} sm={4} md={7} className={classes.image}/>
            <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square
                  style={{background: 'linear-gradient(to bottom, #ffffff, #bba869)', fontFamily: 'Stay Fresh'}}>
                <div className={classes.paper}>
                    <Avatar src="/favicon.png" className={classes.avatar}>
                        <LockOutlinedIcon/>
                    </Avatar>
                    <Typography component="h1" variant="h5" className={classes.typography}>
                        Quên Mật Khẩu
                    </Typography>
                    <Typography component="h1" variant="h5" className={classes.typography_title}>
                        Vui lòng nhập email để nhận mã
                    </Typography>
                    <form className={classes.form} noValidate>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    inputProps={{maxLength: 6}}
                                    variant="outlined"
                                    required
                                    fullWidth
                                    name="code"
                                    label="Mã"
                                    id="code"
                                    onChange={inputCode}
                                    autoComplete="code"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    inputProps={{maxLength: 20}}
                                    variant="outlined"
                                    required
                                    fullWidth
                                    name="newPassword"
                                    label="Mật khẩu mới"
                                    type="password"
                                    id="newPassword"
                                    onChange={inputNewPassword}
                                    autoComplete="current-new-password"
                                    placeholder={"Ít nhất 8 kí tự, 1 thường, 1 in hoa, 1 chữ số"}
                                />
                            </Grid>
                            {!isDisable ? <Grid item xs={12}>
                                <Button
                                    type="button"
                                    //fullWidth
                                    variant="contained"
                                    color="primary"
                                    className={classes.resend}
                                    //disabled={isDisable}
                                    onClick={(event) => {
                                        handleOnClick(event)
                                    }}
                                >
                                    Gửi lại mã
                                </Button>
                            </Grid> : null}
                            {isDisable ? <Grid item xs={12}>
                                    <Typography component="h1" variant="h5" className={classes.typography_title}>
                                        Gửi lại mã: 00:{initialTime}
                                    </Typography>
                                </Grid>
                                : null}
                        </Grid>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={(event) => {
                                summitResetPassword(event)
                            }}
                        >
                            Gửi
                        </Button>
                        <Box mt={5}>
                            <Copyright/>
                        </Box>
                    </form>
                </div>
            </Grid>
            <HueNotification/>
        </Grid>
    );
};

export default ResetPassword;