import React from "react";
import {
    CheckboxGroupInput,
    Create,
    FormDataConsumer,
    maxValue,
    minValue,
    NumberInput,
    RadioButtonGroupInput,
    required,
    showNotification,
    SimpleForm,
    TextInput,
    userLogin
} from "react-admin";
import ResidenceTitle from "./ResidenceTitle";
import {ResidenceForm} from "../../utilities/constants";
import {connect} from 'react-redux';

const validateStars = [minValue(0), maxValue(5)];

const ResidenceCreate = (props) => {
    return (
        <Create title={<ResidenceTitle type="Tạo"/>} {...props}>
            <SimpleForm>
                <TextInput label={"Tên cơ sở"} source={"title"} validate={required()} multiline={true}
                           fullWidth={true}/>
                <TextInput label={"Địa chỉ"} source={"address"} validate={required()} multiline={true}
                           fullWidth={true}/>
                <TextInput label={"Số điện thoại"} source={"phone"} validate={required()} multiline={true}
                           fullWidth={true}/>
                <TextInput label={"Website"} source={"website"} multiline={true} fullWidth={true}/>
                <TextInput label={"Kênh Booking"} source={"booking_channel"} multiline={true} fullWidth={true}/>
                <TextInput label={"Mô tả cơ sở"} source={"description"} multiline={true} fullWidth={true}/>
                <RadioButtonGroupInput label={"Loại cơ sở kinh doanh"} source="business_type"
                                       choices={ResidenceForm.BusinessType}
                                       style={{paddingTop: '10px'}} validate={required()}/>
                <NumberInput label={"Hạng khách sạn"} source={"stars"} validate={validateStars}/>
                <NumberInput label={'Giá phòng'} source={"price"} validate={[required(), minValue(0)]}/>
                <RadioButtonGroupInput label={"Loại phòng"} source="room_type"
                                       choices={ResidenceForm.RoomType}
                                       validate={required()}/>
                <CheckboxGroupInput label={"Tiện nghi chung"} source="common_benefit"
                                    choices={ResidenceForm.CommonBenefit} row={false}
                                    style={{paddingTop: '10px', fontWeight: '100px'}}/>
                <CheckboxGroupInput label={'Tiện nghi trong nhà'} source="indoor_benefit"
                                    choices={ResidenceForm.IndoorBenefit}
                                    style={{paddingTop: '10px'}} row={false}/>
                <CheckboxGroupInput label={"Phong cách"} source="style"
                                    choices={ResidenceForm.Style}
                                    style={{paddingTop: '10px'}} row={false}/>
                <FormDataConsumer>
                    {({formData, ...rest}) => {
                        // console.log('LANGUAGES', formData.languages);
                        if (formData && formData.languages) {
                            if (formData.languages.includes('Khác')) {
                                return (
                                    <div>
                                        <CheckboxGroupInput label={"Ngôn ngữ"} source="languages"
                                                            choices={ResidenceForm.Languages} row={false}/>
                                        <TextInput label={"Ngôn ngữ khác"} source={"other_languages"} fullWidth={true}
                                                   multiline={true} validate={required()}/>
                                    </div>
                                )
                            }
                        }
                        return (
                            <CheckboxGroupInput label={"Ngôn ngữ"} source="languages"
                                                choices={ResidenceForm.Languages} style={{paddingTop: '10px'}}
                                                row={false}/>
                        )
                    }}
                </FormDataConsumer>
                <TextInput label={"Tag Tìm Kiếm"} source={"search_tag"} multiline={true} fullWidth={true}/>
            </SimpleForm>
        </Create>
    )
};

export default connect(undefined, {userLogin, showNotification})(ResidenceCreate);