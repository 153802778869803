import decodeJwt from 'jwt-decode';
import {AppConstant} from "../utilities/constants";

export default {
    login: (credentials) => {
        console.log("Credentials", credentials);
        if (credentials.facebookToken) {
            const request = new Request(`${AppConstant.ApiUrl}/${AppConstant.Routes.Users}/login`, {
                method: 'POST',
                body: JSON.stringify({facebook_token: credentials.facebookToken}),
                headers: new Headers({'Content-Type': 'application/json'}),
            });
            return fetch(request)
                .then(response => {
                    if (response.status < 200 || response.status >= 300) {
                        return Promise.reject();
                    }
                    return response.json();
                })
                .then(({token}) => {
                    const decodedToken = decodeJwt(token);
                    // console.log("decoded", decodedToken);
                    localStorage.setItem('token', token);
                    localStorage.setItem('permissions', decodedToken.role);
                    localStorage.setItem('user_id', decodedToken.id)
                })
        }
        if (credentials.email && credentials.password) {
            const request = new Request(`${AppConstant.ApiUrl}/${AppConstant.Routes.Users}/login`, {
                method: 'POST',
                body: JSON.stringify({email: credentials.email, password: credentials.password}),
                headers: new Headers({'Content-Type': 'application/json'}),
            });
            return fetch(request)
                .then(response => {
                    if (response.status < 200 || response.status >= 300) {
                        return Promise.reject();
                    }
                    return response.json();
                })
                .then(({token}) => {
                    const decodedToken = decodeJwt(token);
                    // console.log("decoded", decodedToken);
                    localStorage.setItem('token', token);
                    localStorage.setItem('permissions', decodedToken.role);
                    localStorage.setItem('user_id', decodedToken.id)
                })
        }
    },
    logout: () => {
        localStorage.removeItem('token');
        localStorage.removeItem('permissions');
        localStorage.removeItem('user_id');
        return Promise.resolve();
    },

    checkError: error => {
        const status = error.status;
        if (status === 401 || status === 403) {
            localStorage.removeItem('token');
            localStorage.removeItem('permissions');
            localStorage.removeItem('user_id');
            return Promise.reject();
        }
        return Promise.resolve();
    },
    checkAuth: () => {
        return localStorage.getItem('token') ? Promise.resolve() : Promise.reject();
    },
    getPermissions: params => {
        console.log("type", params);
        const role = localStorage.getItem('permissions');
        return role ? Promise.resolve(role) : Promise.resolve('guest');
    }
};