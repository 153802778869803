import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import HueNotification from "../notification/HueNotification";
import {makeStyles} from "@material-ui/core/styles";
import {getRandom} from "../../utilities/common";

const images = [
    "https://resources.huetrippal.com/resources/places/5ceba9069373fa376c75422e/images/4e4ce156-75dd-4d73-b907-caeda2eb3118.jpg",
    "https://resources.huetrippal.com/resources/places/5ceba9069373fa376c75422e/images/4bd641b2-1672-47e2-8962-a8216ddc81a2.JPG",
    "https://resources.huetrippal.com/resources/places/5d834d048f9c3315a4910eae/images/161d6321-bc55-4ea7-a165-3cadd89f97bd.jpg",
    "https://resources.huetrippal.com/resources/places/5d8221c38f9c3311040a7a10/images/d7f08afa-8a86-45ba-9104-bc844e2c1a25.jpg",
    "https://resources.huetrippal.com/resources/places/5d8221c38f9c3311040a7a10/images/a5335e9a-ed23-40f5-83c6-a85a0bb31a08.jpg"
];

const useSuccessStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
    },
    typography: {
        fontFamily: "verdana",
        fontSize: '0.9rem'
    },
    typography_email: {
        fontFamily: "verdana",
        fontSize: '0.9rem'
    },
    image: {
        backgroundImage: 'url(' + images[getRandom(0, 4)] + ')',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        background: '#733000',
        margin: theme.spacing(3, 0, 2),
        "&:hover": {
            //you want this to be the same as the backgroundColor above
            backgroundColor: "#733000"
        }
    },
}));

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://huetrippal.com/">
                HueTrippal
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const SignUpFailure = (props) => {
    const classes = useSuccessStyles();

    return (
        <Grid container component="main" className={classes.root}>
            <CssBaseline/>
            <Grid item xs={false} sm={4} md={7} className={classes.image}/>
            <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square
                  style={{background: 'linear-gradient(to bottom, #ffffff, #bba869)', fontFamily: 'Stay Fresh'}}>
                <div className={classes.paper}>
                    <Avatar src="/favicon.png" className={classes.avatar}>
                        <LockOutlinedIcon/>
                    </Avatar>
                    <Typography component="h1" variant="h5" className={classes.typography}>
                        Xác Nhận Email Không Thành Công
                    </Typography>
                    <Typography component="h1" variant="h5" className={classes.typography_email}>
                        Vui lòng liên hệ <a style={{color: '#733000', padding: '0 2px 0 2px'}}
                                            href="mailto:huetrippal@gmail.com" target="_top">Hue Trippal</a> để được hỗ
                        trợ
                    </Typography>
                    <Link href="/#/login" variant="body2" style={{color: '#733000', paddingTop: '50px'}}>
                        Quay lại trang đăng nhập
                    </Link>
                    <Copyright/>
                </div>
            </Grid>
            <HueNotification/>
        </Grid>
    );
};

export default SignUpFailure;