import React, {useState} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import {useNotify} from 'react-admin';
import HueNotification from "../notification/HueNotification";
import {AxiosPost, getRandom} from "../../utilities/common";
import {makeStyles} from "@material-ui/core/styles";
import {AppConstant} from "../../utilities/constants";
import {useHistory} from "react-router-dom"


const images = [
    "https://resources.huetrippal.com/resources/places/5ceba9069373fa376c75422e/images/fe53a957-c941-4869-8ab0-f91f8669203c.jpg"
];

const useLostPasswordStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
    },
    typography: {
        fontFamily: "verdana",
        fontSize: '1rem'
    },
    typography_title: {
        padding: '10px 0 0 0',
        fontFamily: "verdana",
        fontSize: '0.9rem'
    },
    image: {
        backgroundImage: 'url(' + images[0] + ')',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        background: '#733000',
        margin: theme.spacing(3, 0, 2),
        "&:hover": {
            //you want this to be the same as the backgroundColor above
            backgroundColor: "#733000"
        }
    },
}));

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://huetrippal.com/">
                HueTrippal
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const LostPassword = (props) => {
    const notify = useNotify();
    const history = useHistory();
    const [email, setEmail] = useState('');

    const inputEmail = (event) => {
        event.preventDefault();
        setEmail(event.target.value);
    };

    const summitEmail = (event) => {
        event.preventDefault();
        let url = `${AppConstant.ApiUrl}/${AppConstant.Routes.Users}/forgot-password`;
        const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!regexp.test(email)) {
            notify('Email không hợp lệ', 'error');
            return
        }
        let request_body = {
            "email": email,
        };
        AxiosPost('post', url, request_body).then(function (response) {
            if (response.status === 200) {
                notify('Mail xác nhận gửi thành công, vui lòng kiểm tra email', 'info');
                localStorage.setItem("current_email", email);
                history.push('/reset-password');
                //setTimeout(() => , 3000);
                console.log("Login Response", response);
            }
        }).catch(function (error) {
            if (error) {
                if (error.response) {
                    notify('Gửi mail không thành công', 'error');
                    console.log("Error", error.response);
                }
            }
        });
    };

    const classes = useLostPasswordStyles();

    return (
        <Grid container component="main" className={classes.root}>
            <CssBaseline/>
            <Grid item xs={false} sm={4} md={7} className={classes.image}/>
            <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square
                  style={{background: 'linear-gradient(to bottom, #ffffff, #bba869)', fontFamily: 'Stay Fresh'}}>
                <div className={classes.paper}>
                    <Avatar src="/favicon.png" className={classes.avatar}>
                        <LockOutlinedIcon/>
                    </Avatar>
                    <Typography component="h1" variant="h5" className={classes.typography}>
                        Quên Mật Khẩu
                    </Typography>
                    <Typography component="h1" variant="h5" className={classes.typography_title}>
                        Vui lòng nhập email để nhận mã
                    </Typography>
                    <form className={classes.form} noValidate>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email"
                            name="email"
                            onChange={inputEmail}
                            autoComplete="email"
                            autoFocus
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={(event) => {
                                summitEmail(event)
                            }}
                        >
                            Gửi
                        </Button>
                        <Box mt={5}>
                            <Copyright/>
                        </Box>
                    </form>
                </div>
            </Grid>
            <HueNotification/>
        </Grid>
    );
};

export default LostPassword;