import {useEffect, useRef} from "react";

const axios = require('axios');

export const AxiosPost = (method, url, body, headers) => {
    let config = {
        method: method,
        url: url,
        data: body,
        headers: headers,
    };
    return axios(config);
};

export function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

export const getRandom = (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
};