const iconCfg = require("./icon.json"),
    L = require('leaflet'),
    redIcon = new L.Icon(iconCfg.redIcon),
    greenIcon = new L.Icon(iconCfg.greenIcon),
    blackIcon = new L.Icon(iconCfg.blackIcon),
    violetIcon = new L.Icon(iconCfg.violetIcon),
    orangeIcon = new L.Icon(iconCfg.orangeIcon)
;

/**
 * @return {null}
 */
export default function IconColor(color) {
    switch (color) {
        case "red":
            return redIcon;
        case "green":
            return greenIcon;
        case "violet":
            return violetIcon;
        case "black":
            return blackIcon;
        case "orange":
            return orangeIcon;
        default:
            return null;
    }
}