import React from "react";
import {
    ArrayInput,
    BooleanInput,
    CheckboxGroupInput,
    Edit,
    email,
    FormDataConsumer,
    FormTab,
    ImageField,
    ImageInput,
    maxValue,
    minValue,
    NumberInput,
    required,
    SimpleFormIterator,
    TabbedForm,
    TextInput,
    useNotify,
    withDataProvider
} from 'react-admin';
import RestaurantTitle from "./RestaurantTitle";
import {EditToolbar} from "../toolbar/ToolBar";
import {AppConstant, RestaurantForm} from "../../utilities/constants";
import {styles} from "../../utilities/styleField";
import ShowMapHook from "../maps/ShowMapHook";
import {DateTimeInput} from 'react-admin-date-inputs2';
import DateFnsUtils from "@date-io/date-fns";
import viLocale from "date-fns/locale/vi";
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import ImageViewer from "../image_viewer/ImageViewer";

const validateStars = [minValue(0), maxValue(5)];
const validateLongitude = [minValue(-180), maxValue(180)];
const validateLatitude = [minValue(-90), maxValue(90)];


const RestaurantEdit = (props) => {
    const notify = useNotify();
    const validateDates = (values) => {
        if (values) {
            for (let i = 0; i < values.length; i++) {
                if (!values[i]) {
                    continue
                }
                if (!values[i].start_time || !values[i].end_time) {
                    continue
                }
                if (Date.parse(values[i].start_time) > Date.parse(values[i].end_time)) {
                    notify(`Thời gian bắt đầu lớn hơn kết thúc ở mục ${i}`, 'error');
                    return `Thời gian bắt đầu lớn hơn kết thúc ở mục ${i}`;
                }
            }
        }
    };
    // const validateEmail = (email) => {
    //     if(email && email !== "") {
    //         const re = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    //         if (!re.test(String(email).toLowerCase())) {
    //             notify(`Email không hợp lệ`, 'error');
    //             return `Email không hợp lệ`;
    //         }
    //     }
    // }
    return (
        <Edit title={<RestaurantTitle type={"Sửa"}/>} {...props}>
            <TabbedForm redirect={false} toolbar={<EditToolbar/>}>
                <FormTab label={"Vị trí bản đồ"}>
                    <FormDataConsumer>
                        {({formData, ...rest}) => {
                            if (formData.location) {
                                return (
                                    <div>
                                        <NumberInput
                                            label="Kinh độ"
                                            source="location.coordinates.long"
                                            style={styles.inputInline}
                                            validate={validateLongitude}
                                        />
                                        <NumberInput
                                            label="Vĩ độ"
                                            source="location.coordinates.lat"
                                            style={{marginLeft: 50}}
                                            validate={validateLatitude}
                                        />
                                        <ShowMapHook location={formData.location}/>
                                    </div>
                                );
                            } else {
                                return (
                                    <div>
                                        <NumberInput
                                            label="Kinh độ"
                                            source="location.coordinates.long"
                                            style={styles.inputInline}
                                            validate={validateLongitude}
                                        />
                                        <NumberInput
                                            label="Vĩ độ"
                                            source="location.coordinates.lat"
                                            style={{marginLeft: 50}}
                                            validate={validateLatitude}
                                        />
                                    </div>
                                );
                            }
                        }}
                    </FormDataConsumer>
                </FormTab>
                <FormTab label={"Thông tin chung"}>
                    <TextInput label={"Tên cơ sở"} source={"title"} validate={required()} multiline={true}
                               fullWidth={true}/>
                    <TextInput label={"Địa chỉ"} source={"address"} validate={required()} multiline={true}
                               fullWidth={true}/>
                    <TextInput label={"Email"} source={"email"} validate={[required(), email()]} multiline={true}
                               fullWidth={true}/>
                    <TextInput label={"Số điện thoại"} source={"phone"} validate={required()} multiline={true}
                               fullWidth={true}/>
                    <TextInput label={"Website"} source={"website"} multiline={true} fullWidth={true}/>
                    <TextInput label={"Mô tả cơ sở"} source={"description"} multiline={true} fullWidth={true}/>
                    <TextInput label={"Tag Tìm Kiếm"} source={"search_tag"} multiline={true} fullWidth={true}/>
                </FormTab>
                <FormTab label="Hình ảnh">
                    <ArrayInput source="images" style={styles.images}>
                        <SimpleFormIterator disableAdd>
                            <FormDataConsumer>
                                {({formData, scopedFormData, getSource, ...rest}) => {
                                    if (scopedFormData && scopedFormData.url) {
                                        let image = new Image();
                                        let imageUrl = '';
                                        if (scopedFormData.url.includes(AppConstant.ResourcesUrl) || scopedFormData.url.includes('blob:')) {
                                            imageUrl = scopedFormData.url
                                        } else {
                                            imageUrl = `${AppConstant.ResourcesUrl}${scopedFormData.url}`
                                        }
                                        image.src = imageUrl;
                                        return (
                                            <div>
                                                <ImageViewer image={image} imageUrl={imageUrl}
                                                             width={AppConstant.ImageWidth}
                                                             height={AppConstant.ImageHeight}/>
                                            </div>
                                        );
                                    }
                                }}
                            </FormDataConsumer>
                        </SimpleFormIterator>
                    </ArrayInput>
                    <ImageInput source="images" label="Nhập ảnh" accept="image/*" multiple
                                maxSize={AppConstant.MaxImageSize} options={{
                        onDropRejected(files, event) {
                            notify(`Kích thước mỗi hình không lớn hơn ${AppConstant.MaxImageSize / Math.pow(1024, 2)} MB`, "error")
                        }
                    }}>
                        <ImageField style={{display: "none"}} source="url" title=""/>
                    </ImageInput>
                </FormTab>
                <FormTab label="Thực đơn">
                    <ArrayInput source="menu" style={styles.images}>
                        <SimpleFormIterator disableAdd>
                            <FormDataConsumer>
                                {({formData, scopedFormData, getSource, ...rest}) => {
                                    if (scopedFormData && scopedFormData.url) {
                                        let image = new Image();
                                        let imageUrl = '';
                                        if (scopedFormData.url.includes(AppConstant.ResourcesUrl) || scopedFormData.url.includes('blob:')) {
                                            imageUrl = scopedFormData.url
                                        } else {
                                            imageUrl = `${AppConstant.ResourcesUrl}${scopedFormData.url}`
                                        }
                                        image.src = imageUrl;
                                        return (
                                            <div>
                                                <ImageViewer image={image} imageUrl={imageUrl}
                                                             width={AppConstant.ImageWidth}
                                                             height={AppConstant.ImageHeight}/>
                                            </div>
                                        );
                                    }
                                }}
                            </FormDataConsumer>
                        </SimpleFormIterator>
                    </ArrayInput>
                    <ImageInput source="menu" label="Nhập ảnh" accept="image/*" multiple
                                maxSize={AppConstant.MaxImageSize} options={{
                        onDropRejected(files, event) {
                            notify(`Kích thước mỗi hình không lớn hơn ${AppConstant.MaxImageSize / Math.pow(1024, 2)} MB`, "error")
                        }
                    }}>
                        <ImageField style={{display: "none"}} source="url" title=""/>
                    </ImageInput>
                </FormTab>
                <FormTab label={"Loại ẩm thực"}>
                    <FormDataConsumer>
                        {({formData, ...rest}) => {
                            if (formData && formData.cuisine_types) {
                                return (
                                    <div>
                                        <CheckboxGroupInput label={"Loại ẩm thực"} source="cuisine_types"
                                                            choices={RestaurantForm.CuisineTypes} row={false}
                                                            style={{paddingTop: '10px', fontWeight: '100px'}}/>
                                        {formData.cuisine_types.includes('Món châu Á') ?
                                            <TextInput label={"Món châu Á của quốc gia"}
                                                       source={"cuisine_asian_country"}
                                                       fullWidth={true}
                                                       multiline={true} validate={required()}/> : null}
                                        {formData.cuisine_types.includes('Món châu Âu') ?
                                            <TextInput label={"Món châu Âu của quốc gia"}
                                                       source={"cuisine_western_country"}
                                                       fullWidth={true}
                                                       multiline={true} validate={required()}/> : null}
                                    </div>
                                )
                            } else {
                                return (
                                    <div>
                                        <CheckboxGroupInput label={"Loại ẩm thực"} source="cuisine_types"
                                                            choices={RestaurantForm.CuisineTypes} row={false}
                                                            style={{paddingTop: '10px', fontWeight: '100px'}}/>
                                    </div>
                                )
                            }
                        }}
                    </FormDataConsumer>
                </FormTab>
                <FormTab label={'Chế độ ăn'}>
                    <CheckboxGroupInput label={"Lựa chọn"} source="cuisine_choices"
                                        choices={RestaurantForm.CuisineChoices} row={false}
                                        style={{paddingTop: '10px', fontWeight: '100px'}}/>

                </FormTab>
                <FormTab label={'Đặc điểm nhà hàng'}>
                    <FormDataConsumer>
                        {({formData, ...rest}) => {
                            if (formData && formData.restaurant_features) {
                                console.log(formData.restaurant_features)
                                return (
                                    <div>
                                        <CheckboxGroupInput label={"Đặc điểm nhà hàng"} source="restaurant_features"
                                                            choices={RestaurantForm.RestaurantFeatures}
                                                            row={false}
                                                            style={{fontWeight: '100px'}}/>
                                        {formData.restaurant_features.includes('Có giờ giảm giá') ?
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <DateTimeInput source="discount_time" label="Nhập giờ"
                                                               options={{
                                                                   //format: 'dd/mm/yyy, HH:mm',
                                                                   format: 'hh:mm',
                                                                   ampm: false,
                                                                   clearable: true
                                                               }}
                                                               providerOptions={{
                                                                   utils: DateFnsUtils,
                                                                   locale: viLocale
                                                               }}/>
                                            </MuiPickersUtilsProvider> : null}
                                    </div>
                                )
                            } else {
                                return (
                                    <div>
                                        <CheckboxGroupInput label={"Đặc điểm nhà hàng"} source="cuisine_types"
                                                            choices={RestaurantForm.RestaurantFeatures}
                                                            row={false}
                                                            style={{paddingTop: '10px', fontWeight: '100px'}}/>
                                    </div>
                                )
                            }
                        }}
                    </FormDataConsumer>
                </FormTab>
                <FormTab label={'Chương trình giảm giá'}>
                    <BooleanInput label="Có chương trình giảm giá" source="has_discount_time"/>
                    <FormDataConsumer>
                        {({formData, getSource, ...rest}) => {
                            if (formData && formData.has_discount_time) {
                                if (formData.has_discount_time === true) {
                                    return (
                                        <ArrayInput source="discount_time" validate={validateDates}>
                                            <SimpleFormIterator disableAdd disableRemove>
                                                <FormDataConsumer>
                                                    {({formData, scopedFormData, getSource, ...rest}) => {
                                                        if (formData && formData.discount_time) {
                                                            if (formData.has_discount_time === true) {
                                                                return (
                                                                    <div>
                                                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                                            <DateTimeInput validate={required()}
                                                                                           source={getSource("start_time")}
                                                                                           label="Bắt đầu"
                                                                                           options={{
                                                                                               fullWidth: true,
                                                                                               format: AppConstant.TimeFormat,
                                                                                               //format: 'hh:mm',
                                                                                               ampm: true,
                                                                                               clearable: true
                                                                                           }}
                                                                                           providerOptions={{
                                                                                               utils: DateFnsUtils,
                                                                                               locale: viLocale
                                                                                           }}/>
                                                                            <DateTimeInput validate={required()}
                                                                                           source={getSource("end_time")}
                                                                                           label="Kết thúc"
                                                                                           options={{
                                                                                               fullWidth: true,
                                                                                               format: AppConstant.TimeFormat,
                                                                                               //format: 'hh:mm',
                                                                                               ampm: true,
                                                                                               clearable: true
                                                                                           }}
                                                                                           providerOptions={{
                                                                                               utils: DateFnsUtils,
                                                                                               locale: viLocale
                                                                                           }}/>
                                                                        </MuiPickersUtilsProvider>
                                                                    </div>
                                                                )
                                                            }
                                                        }
                                                        return null;
                                                    }}
                                                </FormDataConsumer>
                                            </SimpleFormIterator>
                                        </ArrayInput>
                                    )
                                }
                            }
                            return null;
                        }}
                    </FormDataConsumer>
                </FormTab>
            </TabbedForm>
        </Edit>
    )
};

export default withDataProvider(RestaurantEdit)