import React from "react";
import {
    CheckboxGroupInput,
    Create,
    email,
    FormDataConsumer,
    required,
    showNotification,
    SimpleForm,
    TextInput,
    userLogin
} from "react-admin";
import RestaurantTitle from "./RestaurantTitle";
import {connect} from 'react-redux';
import {RestaurantForm} from "../../utilities/constants";

// const validateStars = [minValue(0), maxValue(5)];

const RestaurantCreate = (props) => {
    return (
        <Create title={<RestaurantTitle type="Tạo"/>} {...props}>
            <SimpleForm>
                <TextInput label={"Tên cơ sở"} source={"title"} validate={required()} multiline={true}
                           fullWidth={true}/>
                <TextInput label={"Địa chỉ"} source={"address"} validate={required()} multiline={true}
                           fullWidth={true}/>
                <TextInput type="email" label={"Email"} source={"email"} validate={[required(), email()]}
                           multiline={true}
                           fullWidth={true}/>
                <TextInput label={"Số điện thoại"} source={"phone"} validate={required()} multiline={true}
                           fullWidth={true}/>
                <TextInput label={"Website"} source={"website"} multiline={true} fullWidth={true}/>
                <TextInput label={"Mô tả cơ sở"} source={"description"} multiline={true} fullWidth={true}/>
                <FormDataConsumer>
                    {({formData, ...rest}) => {
                        //console.log('LANGUAGES', formData.languages);
                        if (formData && formData.cuisine_types) {
                            return (
                                <div>
                                    <CheckboxGroupInput label={"Loại ẩm thực"} source="cuisine_types"
                                                        choices={RestaurantForm.CuisineTypes} row={false}
                                                        style={{paddingTop: '10px', fontWeight: '100px'}}/>
                                    {formData.cuisine_types.includes('Món châu Á') ?
                                        <TextInput label={"Tên quốc gia châu Á"} source={"cuisine_asian_country"}
                                                   fullWidth={true}
                                                   multiline={true} validate={required()}/> : null}
                                    {formData.cuisine_types.includes('Món châu Âu') ?
                                        <TextInput label={"Tên quốc gia châu Âu"} source={"cuisine_western_country"}
                                                   fullWidth={true}
                                                   multiline={true} validate={required()}/> : null}
                                </div>
                            )
                        } else {
                            return (
                                <div>
                                    <CheckboxGroupInput label={"Loại ẩm thực"} source="cuisine_types"
                                                        choices={RestaurantForm.CuisineTypes} row={false}
                                                        style={{paddingTop: '10px', fontWeight: '100px'}}/>
                                </div>
                            )
                        }
                    }}
                </FormDataConsumer>
                {/*<BooleanInput label="Có chương trình giảm giá" source="has_discount_time"/>*/}
                <CheckboxGroupInput label={"Chế độ ăn"} source="cuisine_choices"
                                    choices={RestaurantForm.CuisineChoices} row={false}
                                    style={{paddingTop: '10px', fontWeight: '100px'}}/>
                <CheckboxGroupInput label={'Đặc điểm nhà hàng'} source="restaurant_features"
                                    choices={RestaurantForm.RestaurantFeatures}
                                    style={{paddingTop: '10px'}} row={false}/>
                <CheckboxGroupInput label={"Phù hợp"} source="suitableness"
                                    choices={RestaurantForm.Suitableness}
                                    style={{paddingTop: '10px'}} row={false}/>
                <TextInput label={"Tag Tìm Kiếm"} source={"search_tag"} multiline={true} fullWidth={true}/>
            </SimpleForm>
        </Create>
    )
};

export default connect(undefined, {userLogin, showNotification})(RestaurantCreate);