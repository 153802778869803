import React from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import MyProfile from "../profile/MyProfile";
import {getRandom} from "../../utilities/common";

const images = [
    "https://resources.huetrippal.com/resources/places/5ceba9069373fa376c75422e/images/fe53a957-c941-4869-8ab0-f91f8669203c.jpg"
];


const Dashboard = () => {
    return (
        <Card>
            <CardHeader title="Welcome to Hue Trippal Enterprise site"/>
            <CardContent>
                <p>Một số hình ảnh của Huế</p>
                <img style={{width: '30%', height: '30%'}} src={images[0]} alt=""/>
            </CardContent>
            <CardContent>
                <MyProfile userID={localStorage.getItem('user_id')}/>
            </CardContent>
        </Card>
    )
};

export default Dashboard;